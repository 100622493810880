.comentario-wrapper {
	width: 100%;
}

.resposta-com-comentario {
	padding: 10px 20px 10px 10px !important;
	position: relative;
	.icone-comentario {
		display: none;
		position: absolute;
		top: -6px;
		right: -35px;
		padding: 15px;
		background-color: #fa970f;
		border-radius: 50%;
		color: #fff;
		font-size: 18px;
		&:hover {
			cursor: pointer;
			background-color: #d37c02;
		}
	}
	&:hover,
	&.pin-comentario {
		background-color: #ececec;
		.icone-comentario {
			display: inline;
		}
	}
	.comentario-form-especifico {
		label {
			color: #eb8800;
			margin-bottom: unset;
			margin-top: 7px;
		}
		textarea {
			background-color: #fff;
		}
	}
	.icone-comentario {
		&:hover {
			cursor: pointer;
			background-color: #fa970f;
		}
	}
	.comentario-wrapper {
		width: 100%;
	}
	.comentario-form-especifico {
		&.is-comentario-readonly {
			font-style: italic;
			font-size: 15px;
			font-weight: 400 !important;
			margin: 5px 0 5px 2px;
			border-left: 2px dotted #fa970f;
			padding: 10px 14px;
			color: #a96200;
			line-height: 1.4rem;
			background-color: #ff94001f;
		}
	}
}
