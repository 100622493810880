.hra-wrapper {
	text-align: right;
	i {
		font-size: 18px;
		margin-right: 8px;
	}
	&.warning-message {
		strong {
			text-decoration: line-through;
		}
	}
	&.warning-compacto {
		display: inline;
		margin-left: 5px;
		i {
			margin-right: unset;
		}
	}
}
.warning-tooltip {
	strong {
		color: rgb(255, 217, 0);
		text-decoration: dashed !important;
	}
}
