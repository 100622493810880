/**
* Styles licenciamento
**/
// Required
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// optional
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/badge';

/*
todos os imports do bootstrap 4, pegar o que for necessario:

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';
 */

$gray: #7d7f80;
$gray-lighter: rgb(237, 239, 241);
$gray-light: darken($gray-lighter, 5%);
$gray-dark: darken($gray, 20%);
$light: white;

$primary-color: #09afa7;
$pro-color: #fa970f;

$headerheight: 88px;

#app {
	height: 100vh;

	> .container {
		min-height: calc(100% - #{$headerheight});
		margin-bottom: -32px;
		padding-bottom: 32px;
	}
}

body {
	font-family: 'Lato', 'Helvetica Neue', Verdana, sans-serif;
	font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-weight: 600;
	line-height: 1.1;
	color: inherit;
}

h2 {
	margin-top: 52px;
}

h3 {
	margin-top: 24px;
}

h4 {
	margin-top: 24px;
	margin-bottom: 0;
}

h5 {
	margin-top: 24px;
	font-size: 1rem;
}

header {
	padding-bottom: 36px;
	padding-top: 23px;
	padding-left: 0;
	background-color: #39393d;

	.is-hom & {
		background-color: darkred !important;
	}

	a {
		color: #fff !important;
	}
}

.subhead {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
}

.breadcrumb {
	display: block;
	background-color: transparent;
	font-size: 0.75em;
	text-transform: uppercase;
	margin-bottom: 30px;
	padding: 8px 0;
	list-style: none;
	border-radius: 4px;

	> li {
		display: inline-block;

		+ li:before {
			padding: 0 5px;
			color: #ccc;
			content: '/\00a0';
		}
	}

	> .active {
		color: #777777;
	}
}

.subhead.breadcrumbs-principal {
	display: block;
}
.app-container {
	// WRAPPER HOMOLOGAÇÃO
	&.is-hom-wrapper {
		position: relative;
		background: linear-gradient(to bottom, red, #fff);
		padding: 10px 10px 0px 10px;
		.app-body {
			&.is-hom {
				height: max-content;
				&:before {
					content: 'ambiente de homologação';
					color: #cf4e4e;
					position: absolute;
					text-align: center;
					width: 100%;
				}
			}
		}
	}
	.app-body {
		background: #fff;
	}
}

*:focus,
button:focus {
	outline: none;
	box-shadow: 0 0 0 1px white inset, 0 0 0 3px darken($gray-light, 5%) inset;
}

a:not(.btn) {
	position: relative;
	font-weight: 600;
	transition: color 0.3s ease 0s;
}

a {
	background-color: transparent;
	text-decoration: none;
	color: $primary-color;
}

.pro-mode {
	a {
		color: $pro-color;
	}
}

.page-header {
	background-color: white;
	border: 0 solid transparent;
	display: block;
	font-size: 40px;
	padding: 0;
	overflow: hidden;
	height: auto;
	padding: 0;
	color: #525257;
	font-size: 45px;
}

.page-header:after {
	background-color: $primary-color;
	margin-right: -100%;
	margin-left: 15px;
	top: -7px;
	content: '';
	display: inline-block;
	height: 5px;
	position: relative;
	width: 100%;
	z-index: 0;
}

.img-capa-portal {
	width: 100%;
}

.titulo-principal-portal {
	margin-top: -10rem !important;
	margin-bottom: 10rem !important;

	span {
		background-color: $primary-color;
		padding: 10px;
		line-height: 1.3em;
		color: #fff;
		font-size: 1.5em;
		font-weight: 300;

		b {
			font-weight: 600;
		}
	}
}

.item-icone-capa {
	display: flex;
}

.fundo-icones-capa {
	background-color: $primary-color;
	margin-top: -10rem;
	padding-top: 7rem;
}

.fundo-ajuda-capa {
	background-color: #dde1e4;
	margin-top: 3rem;
	padding-top: 5rem;
	padding-bottom: 5rem;
	margin-bottom: 3rem;
}

.icones-fundo-redondo {
	background-color: #fff;
	width: 140px;
	height: 140px;
	border-radius: 70px;
	text-align: center;
	margin-top: auto;
	margin-bottom: auto;
	box-shadow: 3px 3px 10px #595656;
	margin-left: 2rem;
	margin-right: 3rem;

	img {
		margin-top: 20px;
	}
}

.item-icone-2a-linha-capa {
	background-color: #fff;
	width: 100px;
	height: 100px;
	border-radius: 70px;
	text-align: center;
	margin-top: auto;
	margin-bottom: auto;
	box-shadow: 3px 3px 10px #595656;
	margin-left: 2rem;
	margin-right: 3rem;

	img {
		margin-top: 20px;
		max-width: 68px;
		max-height: 56px;
	}

	h2 {
		color: #fff !important;
		width: 110px;
		font-weight: 500;
		font-size: 1.2em;
		margin-top: 44px;
	}
}

.consulta-habite-se {
	max-height: 66px;
	margin-top: 14px;
}

.titulo-consulta-habite-se {
	margin-top: 38px;
}

.texto-item-icone-capa {
	flex: 4;
	color: #fff !important;
	margin-top: 1em;

	p {
		font-size: 1.2em;
		font-weight: 300;
		width: 450px;
	}
}

.cta-item-icone {
	background-color: #fff;
	text-transform: uppercase;
	color: $primary-color;
	padding: 6px 8px;

	&:after {
		font-family: 'FontAwesome';
		content: '\f054';
		margin-left: 8px;
	}
}

.primeira-linha-capa {
	border-bottom: 1px dashed #fff;
	padding-bottom: 40px;
	margin-left: 2rem;
	margin-right: 2rem;

	a:hover {
		color: #fff !important;
		text-decoration: none !important;
		opacity: 0.9;
	}
}

.segunda-linha-capa {
	padding-bottom: 40px;
	margin-left: 2rem;
	margin-right: 2rem;
	padding-top: 40px;
	display: flex;

	a:hover {
		color: #fff !important;
		text-decoration: none !important;
		opacity: 0.9;
	}

	div {
		flex: 1;
		margin-bottom: 50px;
	}
}

// @media (max-width: 992px) {
// 	.segunda-linha-capa {
// 		div.item-4 {
// 			flex: 0 !important;
// 		}
// 	}
// }

@media (max-width: 768px) {
	.primeira-linha-capa {
		margin-left: 0;
		margin-right: 0;
	}

	.icones-fundo-redondo {
		width: 110px;
		height: 110px;

		img {
			max-width: 70px;
		}
	}

	.texto-item-icone-capa p {
		max-width: 340px;
	}

	.segunda-linha-capa {
		div.item-4 {
			flex: 1 !important;
		}
	}
}

.creditos-foto {
	position: relative;
	left: 10px;
	margin-top: -22px;
	color: #fff;
	font-size: 10px;
	text-shadow: 2px 2px 2px #000;
}

@media (max-width: 576px) {
	.titulo-principal-portal {
		margin-top: -7rem !important;
		margin-bottom: 7rem !important;

		span {
			font-size: 1em;
		}
	}

	.icones-fundo-redondo {
		margin-left: 1.5rem;
		margin-right: 2rem;
	}

	.texto-item-icone-capa p {
		width: 250px;
	}

	.item-icone-2a-linha-capa {
		margin-left: 0;
		margin-right: 2rem;
	}
}

.titulo-box-ajuda-capa {
	font-size: 1.6em;
	font-weight: 600;
	color: #39393d;
}

.itens-ajuda-capa {
	border-left: 1px dashed #39393d;
	padding-left: 3rem;
	margin-top: 2rem;

	p a {
		text-transform: uppercase;
		font-size: 1.2em;
	}

	p {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.btn-username-barra-topo {
	color: #fff !important;
}

.username-barra-topo {
	color: #fff !important;
	font-size: 18px;
	font-style: italic;
	margin-top: -4px;

	&:before {
		content: '\f007';
		font-family: FontAwesome;
		margin-right: 8px;
		font-style: normal;
	}
}


.cabecalho {
	display: flex;
	flex-wrap: wrap;

	div.logo-pref-header {
		flex: 1;
	}

	div.conjunto-icones-barra-topo {
		flex: 3;
	}

}

@media (max-width: 768px) {
	.btn-novidades, .btn-ajuda {
		font-size: 0 !important;
		margin-top: 0 !important;
		padding: 8px 0 !important;
	}

	.username-barra-topo {
		font-size: 0 !important;
		&::before  {
			font-size: 20px !important;
		}
	}
}

// @media (max-width: 576px) {
// .btn-novidades, .btn-ajuda {
// 	display: none !important;
// }
// }

.pro-mode {
	.page-header:after {
		background-color: $pro-color;
	}
}

.form-group-row {
	display: flex;

	> * {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;

		&:not(:last-child) {
			margin-right: 16px;
		}
	}
}

// .rjsf,
.disabled-form,
.form-docs {
	margin-left: -15px;
	margin-right: -15px;

	button {
		margin-left: 15px;
	}
}

.form-group {
	margin-top: 8px;
	margin-bottom: 16px;
	display: block;

	label {
		font-size: 18px;
		color: #555;
		margin: 0;
		letter-spacing: normal;
		text-transform: none;
		font-weight: 400;
		display: block;
		width: 100%;
		span.required {
			color: red;
		}
	}

	legend {
		font-size: 1.5em;
		margin-top: 0;
		margin-bottom: 0.5rem;
		font-weight: 600;
		line-height: 1.1;
		color: inherit;
		// padding: 0 15px;
		padding: 0;
		word-break: break-word;
	}

	.list-blocks {
		padding-left: 0;
	}
}

.field-description {
	white-space: pre-wrap;
	font-size: 14px;
	color: $gray;
	margin-top: 10px;
}

.field-description-documentos {
	padding-left: 16px;
	font-size: 14px;
	margin-top: -8px;
	margin-bottom: 16px;
	color: $gray;
}

.field-help {
	padding-left: 5px;
	font-size: 16px;
	margin-top: -8px;
	margin-bottom: 16px;
	color: $gray;
}

.field-object {
	display: block;
}

div[class*='col-'] {
	display: inline-block;
}

div[class*='col-'] {
	> .cdl-field {
		div[class*='col-'] {
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}
}

.checkbox input {
	margin-right: 8px;
	position: relative;
	top: -2px;
}

.input-with-icon {
	position: relative;

	i {
		position: absolute;
		right: 2px;
		top: 2px;
		font-size: 16px;
		padding: 8px 12px;
	}
}

.form-control:focus {
	border-color: $primary-color;
	box-shadow: 0 0 8px darken($primary-color, 15%);
	outline: 0 none;
}

.pro-mode {
	.form-control:focus {
		border-color: $pro-color;
		box-shadow: 0 0 8px darken($pro-color, 15%);
	}
}

.form-control {
	margin-top: 6px;
	border: 2px solid #c5c5c9;
	height: auto;
	padding: 10px 12px;
	font-size: 1em;
	border-radius: 0;

	&[class*='col-'] {
		margin-left: -15px;
	}
}

.form-control {
	.fa-over {
		margin-right: 16px;
		font-size: 18px;
		margin-left: -6px;
		position: relative;
		top: 4px;
		text-shadow: -2px 2px 0px white, 0px -3px 0px white;

		.icon-tooltip {
			display: none;
			opacity: 0;

			max-width: 50vw;
			background-color: rgba(0, 0, 0, 0.8);
			text-shadow: none;
			color: white;
			padding: 8px 16px;
			position: absolute;
			font: 14px 'Lato', 'Helvetica Neue', Verdana, sans-serif;
			width: 18em;
			text-align: center;
			left: -8px;
			transition: opacity 1s;
		}
	}

	&:hover {
		.icon-tooltip {
			display: block;
			opacity: 1;
			transition: opacity 1s;
		}
	}
}

.disabled-form {
	.hideable {
		display: none;
	}

	.list-item {
		background-color: $gray-light;
	}
}

.copy-button {
	position: absolute;
	right: 16px;
	margin-top: 24px;

	label {
		background-color: lightgrey;
		@extend .btn-secondary;
	}

	.input-hidden {
		display: none;
	}

	.input-hidden:checked + span {
		color: black;
	}
}

.list-interactive {
	list-style-type: none;
	padding-left: 0;
	margin-top: 10px;

	li {
		padding-left: 0;
		border: 1px solid lightgrey;
		padding: 8px 16px;
		cursor: pointer;

		&:hover {
			background-color: #eee;
		}

		.list-item-link {
			color: inherit;
			font-weight: normal;
			text-decoration: none;
		}

		> .list-item-link {
			display: block;
			height: 100%;
			margin: -8px -16px;
			padding: 8px 16px;
		}

		+ li {
			border-top: none;
		}
	}
}

.list-blocks {
	list-style-type: none;
	border: 2px solid lightgrey;

	li {
		padding-left: 0;
		padding: 8px 16px;
		border: 0px solid lightgrey;
		border-bottom-width: 1px;

		&:last-child {
			border-bottom-width: 0px;
		}
	}

	.list-item-block {
		display: block;
		font-size: 18px;
		margin-left: 0;
	}
}

.list-item-block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	font-size: 16px;
	margin-left: -16px;

	@media (min-width: map-get($grid-breakpoints, 'md')) {
		flex-wrap: nowrap;
	}

	&.vertical {
		flex-direction: column;

		> div {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			@media (min-width: map-get($grid-breakpoints, 'md')) {
				flex-wrap: nowrap;
				align-items: baseline;
			}
		}
	}

	.list-item-title {
		flex: 2 1 1.4em;
		font-weight: bold;
		margin-left: 16px;
	}

	.list-item-description {
		flex: 1 0 100%;
		margin-left: 16px;

		@media (min-width: map-get($grid-breakpoints, 'md')) {
			flex-basis: 140px;
		}

		@media (min-width: map-get($grid-breakpoints, 'lg')) {
			flex-basis: 140px;
			font-size: 14px;
		}
	}

	.list-item-download {
		flex: 0 0 100%;
		margin-left: 16px;

		@media (min-width: map-get($grid-breakpoints, 'md')) {
			flex-basis: 112px;
		}
	}

	.list-item-date {
		flex: 0 0 auto;
		margin-left: 16px;
		white-space: nowrap;

		@media (min-width: map-get($grid-breakpoints, 'md')) {
			margin-left: 8px;
			font-size: 14px;
			text-align: right;
		}

		// @media (min-width: map-get($grid-breakpoints, 'lg')) {
		// 	font-size: 16px;
		// }
	}

	.list-item-status {
		width: 148px;
		font-style: italic;
		opacity: 0.5;
		margin-left: 16px;

		@media (min-width: map-get($grid-breakpoints, 'md')) {
			text-align: right;
		}
	}

	.processo-link {
		width: 148px;
		white-space: nowrap;
		margin-left: 16px;

		@media (min-width: map-get($grid-breakpoints, 'md')) {
			text-align: right;
		}
	}

	.warning-message,
	.error-message,
	&.vertical .warning-message,
	&.vertical .error-message {
		margin-left: 16px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		@media (min-width: map-get($grid-breakpoints, 'md')) {
			flex-wrap: nowrap;
		}

		.fa {
			margin-top: 4px;
			margin-right: 8px;
		}
	}

	a {
		// flex: 0 0 142px;
		@media (min-width: map-get($grid-breakpoints, 'md')) {
			text-align: right;
		}
	}
}

.embed-list {
	list-style: none;
	padding: 0 16px;

	.embed-list-item {
		margin-bottom: 1px;
	}
}

.warning-message .download-item {
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: #eee;
	}

	.description {
		flex-grow: 1;
		margin-left: 16px;
		font-weight: 700;
	}

	.actions,
	.cod {
		flex-grow: 0;
	}
}

.processo-block {
	p {
		margin-bottom: 0;
	}

	.cod {
		display: inline-block;
		margin-right: 16px;
	}

	.description {
		font-weight: 700;
	}

	.address-list {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.address {
		color: grey;
		margin-right: 16px;

		.svg-inline--fa {
			margin-right: 8px;
		}
	}
}

a.list-link {
	font-weight: inherit;
	color: inherit;
	text-decoration: none;
}

.lead,
p.lead {
	font-size: 1.25em;
	margin-bottom: 16px;
}

@mixin btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	user-select: none;
	font-size: 18px;
	padding: 8px 16px;
}

.btn:disabled {
	cursor: not-allowed;
}

.btn-primary {
	@include btn();
	color: #fff;
	border: 1px solid transparent;
	background-color: $primary-color;
	transition: all 0.5s ease;
	line-height: 18px;

	&:hover,
	&:focus {
		background-color: darken($primary-color, 10%);
	}

	&:focus {
		background-color: darken($primary-color, 10%);
		outline: 1px solid darken($primary-color, 10%);
		outline-offset: -4px;
	}

	&:active {
		background-color: darken($primary-color, 10%) !important;
		border-color: 1px solid darken($primary-color, 10%);
		transform: scale(0.99);
	}
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
	border-color: darken($primary-color, 10%);
}

.btn-primary:hover {
	border-color: darken($primary-color, 10%);
}

.btn-outline-primary {
	color: $primary-color;
	border-color: $primary-color;

	&:hover {
		background-color: $primary-color;
		border-color: $primary-color;
	}
}

.btn-debug {
	@include btn();
	background-color: yellow;
	color: red;

	&:hover {
		background-color: darken(yellow, 5%);
	}

	.fa {
		font-size: 1.2em;
		font-weight: normal;
		margin-left: 5px;
	}
}

.pro-mode {
	.btn-primary {
		background-color: $pro-color;
	}
}

.btn-secondary {
	@include btn();
	color: #333;
	background-color: $gray-lighter;
	line-height: 18px;

	&:hover {
		color: #333;
		background-color: darken($gray-lighter, 10%);
	}
}

.btn-link {
	cursor: pointer;
	border: none;
	color: $primary-color;
	background-color: transparent;
	padding-left: 0;
	padding-right: 0;
	margin-left: 8px;
}

.pro-mode {
	.btn-link {
		color: $pro-color;
	}
}

.btn {
	&.btn-link {
		padding-top: 0;
	}
}

.btn-icon {
	@include btn();
	background-color: transparent;

	&:hover {
		background-color: lightgrey;
	}
}

.header-btn {
	&:hover {
		color: $primary-color;
		background: rgba(255, 255, 255, 0.1);
	}
}

// ERROR SCREEN
.error-screen {
	min-height: calc(100% - #{$headerheight});
	margin-bottom: -32px;
	padding-bottom: 32px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: #333;

	> * {
		width: 340px;
		max-width: 100%;
		text-align: left;
	}

	.error-header {
		font-size: 80px;
		margin-bottom: 48px;
		color: transparentize(#000000, 0.8);

		.error-label {
			color: #333;
			display: block;
			font-size: 32px;
			line-height: 32px;
		}
	}

	.logo {
		margin-top: 48px;

		img {
			width: 196px;
		}
	}
}

.nav {
	flex-wrap: nowrap;
}

.nav-pills {
	margin-top: 32px;
	margin-bottom: 16px;
	padding-left: 0;
	list-style: none;

	> li {
		margin-right: 32px;
		display: inline-block;
		position: relative;

		button {
			font-size: 14px;
			text-transform: uppercase;
			font-weight: bold;
			white-space: nowrap;
			margin-bottom: 10px;
			display: inline-block;
			cursor: pointer;
			padding: 10px 0 3px 0;
			color: $primary-color;
			border: none;
			background: transparent;

			&:last-child {
				margin-right: 0;
			}

			&[disabled] {
				color: gray !important;
			}
		}
	}

	> li.active > button,
	> li.active > button:hover,
	> li.active > button:focus {
		color: $primary-color;
		background: transparent;
		border-bottom: 2px solid;
		border-radius: 0;
		padding: 10px 0 3px 0;
	}
}

.pro-mode {
	.nav-pills {
		> li {
			button {
				color: $pro-color;
			}
		}

		> li.active > button,
		> li.active > button:hover,
		> li.active > button:focus {
			color: $pro-color;
		}
	}
}

.error-detail {
	padding-left: 18px;
}

p:empty + .error-detail {
	margin-top: -16px;
}

// TOOLBAR

.toolbar {
	display: flex;
	margin: 16px 0;
	justify-content: flex-end;
	gap: 10px;

	&.secondary {
		justify-content: flex-start;
	}

	// &.toolbar-right {
	// 	flex-direction: row-reverse;
	// }
}

.toolbar-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 10px;

	.filter {
		justify-self: stretch;
		padding-bottom: 10px;
		margin-top: 0px;
	}
}

.filter {
	margin-top: 00px;
	border: 0px solid red;
}

// LOADER

.loader-wrapper {
	background-color: rgba(255, 255, 255, 0.7);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&.modal-box {
		background-color: rgba(0, 0, 0, 0.5);
	}

	span {
		display: inline-block;
		padding: 4px 16px;
		// background-color: rgba(255, 255, 255, 0.5);
	}

	.loader {
		border-radius: 50%;
		height: 65px;
		width: 65px;
		margin-top: 16px;
		animation: spin 1s infinite linear;
		border: 10px solid $primary-color;
		border-top-color: transparent;
	}

	&.block-loader {
		position: relative;
	}
}

.pro-mode {
	.loader-wrapper {
		.loader {
			border-color: $pro-color;
		}
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

// ERRORS AND WARNINGS

ul.error-block {
	padding-left: 0;
	list-style: none;
}

.error-block {
	margin-bottom: 16px;
	margin-top: 8px;
}

.error-message {
	margin-left: 5px;
	color: darken(red, 25%);

	.fa {
		color: darken(red, 15%) !important;
	}
	.info-message {
		.fa {
			color: #09afa7 !important;
		}
	}
	.warning-message {
		.fa {
			color: #af9209 !important;
		}
	}
}

.warning-message {
	margin-left: 5px !important;
	color: #856404;
	line-height: 18px;
	margin-top: 14px;
	display: flex;

	.fa {
		color: adjust-hue(saturate(darken(yellow, 10%), 50%), -10%);
	}

	&:before {
		content: '\f071';
		font-family: 'FontAwesome';
		margin-right: 10px;
		font-size: 20px;
		color: #deb020;
		font-style: normal;
		// padding-top: 6px;
	}
	&.error-block {
		&:before {
			content: unset;
		}
	}
}

.alert-info {
	color: #856404;
	background-color: transparent;

	&:before {
		content: '\f071';
		font-family: 'FontAwesome';
		float: left;
		margin-right: 10px;
		font-size: 20px;
		color: #deb020;
	}
}

.info-message {
	margin-left: 5px;
	color: adjust-hue(saturate(darken(cyan, 30%), 20%), -15%);

	.fa {
		color: adjust-hue(saturate(darken(cyan, 10%), 50%), -10%);
	}
}

.form-control.form-control-error {
	border: 2px solid red;
}

.processo-link {
	cursor: pointer;
	background-color: transparent;
	color: $primary-color;
	text-decoration: none;
}

.pro-mode {
	.processo-link {
		color: $pro-color;
	}
}

.processo-link:hover {
	text-decoration: none;
	font-weight: bold;
}

.refresh-field {
	float: right;
	margin-right: 15px;
	margin-top: -29px;
	position: relative;
	z-index: 2;
	color: $primary-color;
}

.floating-list-wrapper {
	position: relative;
}

.input-floating-list {
	display: block;
	position: absolute;
	width: 1000px;
	max-width: 100%;
	border: 1px solid $gray-dark;
	background-color: $light;
	top: 100%;
	max-height: 240px;
	overflow-y: auto;
	z-index: 10;

	.results-count {
		margin: 8px 16px;
		color: $gray;
	}

	.results-list {
		margin-top: 0;
		margin-bottom: 0;
		background-color: $light;
		padding: 0;
		list-style: none;
		border: 1px solid $gray-light;

		.results-item {
			.id {
				white-space: nowrap;
			}

			.results-anchor {
				margin: -16px;
				padding: 16px 32px;
				color: inherit;
				display: block;

				.input-group-lic {
					margin-bottom: 0;

					.label {
						margin-bottom: 8px;
					}

					.value {
						line-height: 1;
					}
				}

				.box {
					margin-top: 16px;
				}
			}

			&:not(:last-child) {
				border: solid 1px transparent;
				border-bottom-color: $gray-light;
			}

			&:hover,
			.results-anchor:hover {
				cursor: pointer;
			}

			padding: 8px 16px;
			font-size: 0.9rem;

			&:hover,
			.results-anchor:hover,
			&.fake-focus,
			.results-anchor.fakefocus {
				background-color: $gray-lighter;
			}
		}
	}
}

// OBJECT TITLES

.title-with-id {
	.id,
	.codigo {
		margin-right: 16px;
		font-weight: bold;
	}
}

.title-with-button {
	margin: 6px 0;
	padding: 7px 6px 7px 12px;
	border: 2px solid #c5c5c9;
	min-height: 20px;
	display: flex;
	justify-content: space-between;

	&-white-space-normal {
		white-space: normal;
	}

	button {
		@include btn();
		border: none;
		background: transparent;
		height: 38px;
		width: 38px;
		margin-top: -7px;
		margin-bottom: -8px;
		margin-right: -6px;
		float: right;
		padding: 6px 12px;

		.fa {
			color: $gray;
		}

		&:hover {
			background: $gray-lighter;
		}
	}

	&.read-only {
		background-color: $gray-lighter;
	}
}

.input-group-lic {
	position: relative;

	.label {
		margin-bottom: 8px;
	}
}

.loading-button {
	position: absolute;
	right: 5px;
	top: 9px;
	padding: 8px 12px;
	color: grey;
	font-size: 14pt;
	z-index: 1000;
}

button.btn-link-pesquisa-externa {
	color: white;
	display: inline-block;
	background-color: #7995a9;
	border-radius: 12px;
	padding: 0px 8px;
	width: 210px;
	border-width: 0;
	margin-left: 16px;
	margin-top: 10px;
	margin-bottom: 10px;

	&:hover {
		text-decoration: none;
		font-weight: bold;
		background-color: #5e778a;
	}
}

.field-object {
	padding: 0px;
	margin-top: 0px;
}

.pagination {
	display: block;
	margin: 32px;

	.page {
		display: inline-block;
		color: $gray-dark;
		padding: 8px 16px;
		text-decoration: none;
		cursor: pointer;
		user-select: none;

		&.disabled {
			opacity: 0.5;
		}

		&.active {
			background-color: $primary-color;
			color: white;
			border-radius: 5px;
		}

		&:hover:not(.active) {
			background-color: $gray-lighter;
			border-radius: 5px;
		}
	}
}

.alert.warning {
	background-color: transparent;
	color: #856404;

	&:before {
		content: '\f071';
		font-family: 'FontAwesome';
		float: left;
		margin-right: 10px;
		font-size: 20px;
		color: #deb020;
	}
}

.alert.success {
	background-color: $primary-color;
	color: $light;
}

.alert-indisponivel {
	margin-left: 0 !important;
	margin-right: 0 !important;
	font-size: 18px;

	i {
		margin-right: 10px;
	}
}

.alert {
	padding: 15px;
	margin-bottom: 20px;
	margin-left: 16px;
	margin-right: 16px;
	border: 0px solid transparent;
	border-radius: 0px;
	background-image: none;
	line-height: 1.1;
	width: auto;

	h3 {
		font-weight: 400;
		font-size: 24px;
		margin-bottom: 8px;
		margin-top: 0;
	}

	p {
		margin-bottom: 8px;
	}
}

// A organizar

.titulo-planilhas {
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 700;
	margin-top: 20px;
	margin-left: 16px;
}

.btn-planilhas {
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 700;

	&:before {
		content: '+';
		margin-right: 8px;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: #0f615d;
	}
}

.btn-planilhas.btn-planilhas-p {
	font-size: 14px;
}

.planilha {
	width: 100%;
	margin: 60px 0;

	h2,
	h3 {
		margin-bottom: 0;
		margin-top: 16px;
	}

	.form-group {
		margin-bottom: 8px;
	}

	.form-control {
		padding: 6px 12px;
		margin-top: 0;
	}

	label {
		font-size: 14px;
	}
}

.borda td,
.borda tr {
	border: 1px solid #c5c5c9;
	text-align: center;
}

select.form-control {
	-webkit-appearance: none;
	appearance: none;
	position: relative;
	cursor: pointer;
	margin-top: 6px;
	padding: 7px 6px 7px 12px;
	border: 2px solid #c5c5c9;
	height: 40px;
	display: flex;
}

.select-wrapper {
	position: relative;

	&:before {
		pointer-events: none;
		position: absolute;
		content: '';
		z-index: 1;
		border-top: none;
		border-right: none;
		border-bottom: 3px solid #c5c5c9;
		border-left: 3px solid #c5c5c9;
		border-bottom-left-radius: 2px;
		width: 10px;
		height: 10px;
		top: 50%;
		right: 16px;
		cursor: pointer;
		-webkit-transform: translateY(-50%) rotate(-45deg);
		-moz-transform: translateY(-50%) rotate(-45deg);
		-o-transform: translateY(-50%) rotate(-45deg);
		transform: translateY(-50%) rotate(-45deg);
	}
}

.radio-inline {
	margin: 0 20px 0 0;
	display: inline-block;

	> [type='radio']:not(:checked),
	> [type='radio']:checked {
		position: absolute;
		left: -9999px;

		+ label {
			position: relative;
			padding-left: 38px;
			cursor: pointer;

			&::before {
				position: absolute;
				left: 0;
				top: -2px;
				width: 24px;
				height: 24px;
				content: '';
				border: 2px solid #c5c5c9;
				background: #fff;
				border-radius: 50%;
			}

			&:hover {
				border-color: #09afa7;
			}

			// &::after {
			// 	position: absolute;
			// 	background-color: #09afa7;
			// 	content: '';
			// 	width: 12px;
			// 	height: 12px;
			// 	top: 4px;
			// 	left: 6px;
			// 	transition: all 0.2s;
			// 	border-radius: 50%;
			// }
		}
	}

	> [type='radio']:checked + label:before {
		border: 2px solid #09afa7;
	}

	> [type='radio']:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}
}

.ant-calendar-picker-input[readonly] {
	background-color: white !important;
}

// deprecated: using 'btn-top calendar' now
.btn-calendar {
	background-color: transparent;
	border: none;
	padding: 0 8px;
	cursor: pointer;
	position: absolute;
	bottom: 4px;
	right: 16px;
	color: #687c80;
	height: 32px;
	margin: 4px;
	width: 32px;
}

.btn-top {
	float: right;
	margin-top: 0px;
	padding: 8px 20px;

	&.calendar {
		margin-top: -40px;
		margin-right: -15px;

		&:hover {
			background-color: transparent;
		}

		&:active,
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}

.accordion {
	.header {
		border-top: 1px solid $gray-lighter;
		border-bottom: 1px solid $gray-lighter;

		button {
			width: calc(100% + 32px);
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			white-space: nowrap;
			margin-bottom: 10px;
			text-align: left;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			padding: 8px 16px;
			margin: 0 -16px;
			color: $primary-color;
			border: none;
			background: transparent;
			border-bottom: 2px solid transparent;

			.fa {
				font-size: 16px;
				margin-top: 2px;
			}

			&:focus {
				border-bottom: 2px solid $primary-color;
			}

			&.active {
				color: $primary-color;
				border-bottom-color: $primary-color;
				background: transparent;
				border-radius: 0;
				padding: 10px 0 3px;
			}
		}
	}

	.content {
		opacity: 0;
	}

	.content.active {
		opacity: 1;
		animation: fadein 1s;
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.table {
	width: 100%;

	th,
	td {
		border: 1px solid lighten($gray, 30%);
		padding: 4px 16px;
	}
}

.table-responsive-md {
	@media (max-width: 767.98px) {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
}

.atividade,
.subunidade {
	background: $gray-lighter;

	.btn-secondary {
		margin: 4px 0;
	}
}

.form-object {
	background-color: $gray-lighter;
	padding: 16px;
	margin-bottom: 16px;

	.form-object-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-right: -16px;

		h3 {
			margin: 0;
			font-size: 16px;
			text-transform: uppercase;
			opacity: 0.5;
		}

		.btn {
			background-color: transparent;
			padding-left: 16px;
			padding-right: 16px;
			font-size: 18px;
			min-width: 44px;

			&:hover {
				background-color: darken($gray-lighter, 15%);
			}
		}
	}

	p {
		margin: 0;
	}

	.btn-secondary {
		margin: 4px 0;
	}
}

.checkboxes-list {
	display: flex;
	margin: 0 0 32px;

	.form-check {
		margin-right: 32px;
		white-space: nowrap;
	}

	&.stack {
		flex-direction: column;
		margin-top: 15px;
		margin-left: 10px;
		.container-checkboxes {
			padding: 0px !important;
		}
	}
}

.table-responsive {
	width: 100%;
	overflow-x: auto;
	border: 1px solid $gray-light;
}

.table-like {
	font-size: 0.875rem;
	line-height: 1.75rem;
	width: auto;
	display: flex;
	text-align: center;

	> * {
		flex: 1 0 auto;
	}

	div {
		min-height: auto;
	}

	.tipo {
		background: lightgray;
		font-weight: bold;
		color: #333;
		height: 24px;
		min-height: auto;
		line-height: 1.6;
	}

	// .input,
	// .label {
	// 	box-sizing: border-box;
	// 	margin: -1px 0 0 -1px;
	// 	border-top: 1px solid $gray-light;
	// 	border-left: 1px solid $gray-light;
	// 	white-space: nowrap;
	// 	overflow: hidden;
	// }

	// .label {
	// 	padding: 0 4px;
	// }

	// .input {
	// 	width: 55px;
	// 	text-align: center;

	// 	input {
	// 		text-align: center;
	// 		border: none;
	// 		width: calc(100% - 1px);
	// 		height: calc(100% - 1px);
	// 		margin: 0;
	// 		display: block;

	// 		&:focus {
	// 			outline: none;
	// 			border: 1px solid $primary-color;
	// 		}
	// 	}
	// }

	.block-row {
		display: flex;

		> * {
			flex: 1 0 auto;
		}
	}

	.title-block {
		// flex: 0 0 190px;
		text-align: left;
		position: sticky;
		left: 0;
		background: white;
		border-right: 1px solid $gray-light;
		// box-shadow: rgba(0, 0, 0, 0.05) 2px 0 2px, rgba(0, 0, 0, 0.02) 4px 0 5px;

		.especie {
			// padding: 2px 0 0 16px;
			// background: lightgrey;
			// min-height: 29px;
			.droppable {
				background-color: #eee;

				&.dragging {
					background-color: lightblue;
				}
			}

			// > .input {
			// 	input {
			// 		padding: 0 16px;
			// 	}
			// }
		}

		.tipo,
		.header {
			padding: 0 16px;
			min-height: 29px;
			align-items: center;
		}
	}

	.title-block,
	.area-block,
	.total-block {
		.especie {
			display: flex;

			> * {
				flex: 1 0 auto;
			}
		}
	}

	.area-block {
		min-width: auto;

		.header {
			background-color: $gray-lighter;
			border-right: 1px solid #c5c5c9;
		}

		.especie {
			&.tipo {
				display: grid;
				border: 0px;
				min-height: 29px;

				.tipo-campo {
					border: 1px solid #c5c5c9;
					border-collapse: collapse;
				}
			}

			.campos {
				display: flex;

				.campo {
					border: 1px solid #c5c5c9;
					width: 100%;
				}
			}
		}
	}

	.total-block {
		flex: 0 0 55px;
		min-width: 157px;
		position: sticky;
		// box-shadow: rgba(0, 0, 0, 0.05) -2px 0 2px, rgba(0, 0, 0, 0.02) -4px 0 5px;
		right: 0;
		background: white;
		border-left: 1px solid $gray-light;

		.tipo,
		.header {
			padding: 0 16px;
			min-height: 29px;
			align-items: center;
		}

		.especie {
			min-height: 29px;
		}

		.campos {
			display: flex;

			.campo {
				border: 1px solid #c5c5c9;
				width: 100%;
			}
		}
	}

	.header {
		// height: 122px;
		border-bottom: 1px solid $gray-light;
		line-height: 3;
	}

	.header,
	.cat-block {
		display: flex;
		flex-direction: column;
		justify-content: stretch;

		> * {
			flex-grow: 1;
		}
	}

	.small-area {
		font-size: 8pt;
		padding: 6px 3px;
		border: 0px solid #c5c5c9;
	}
}

.home-link-wrapper {
	text-decoration: none !important;
	min-height: 200px;

	h2 {
		text-decoration: none !important;
		font-size: 1.2em;
	}

	a {
		color: $gray-dark;
	}

	div {
		transition: border-color 0.5s;
	}

	@media (min-width: 992px) {
		&.limitado {
			width: 13.333333328% !important;
			flex: 0 0 13.333% !important;
		}
	}

	span img {
		margin-top: 20px;
		margin-bottom: 8px;

		@media (max-width: 1199.98px) {
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}

	a:hover {
		text-decoration: none !important;
		color: $primary-color !important;

		div {
			border-color: $primary-color !important;
		}
	}
}

// MESSAGE DOCK
.message-dock {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2000;

	.container {
		list-style: none;
		padding: 0;
	}

	.message-item {
		position: relative;
		background-color: $primary-color;
		color: $light;
		border-radius: 4px;
		padding: 16px;
		padding-right: 48px;
		margin-left: 16px;
		margin-right: 16px;
		margin-bottom: 8px;

		.btn {
			position: absolute;
			top: 8px;
			right: 8px;
			width: 40px;
			height: 40px;
			color: $light;
			opacity: 0.8;

			&:hover {
				background-color: transparentize($light, 0.8);
			}
		}
	}
}

.bg-destaque {
	background: #ffdf57;
}

.doc-necessarios {
	padding: 20px;
	margin: 10px 0 30px;
	background: #ededee;
}

// MODAL
.modal-dialog {
	max-width: 750px;
}

.modal-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: transparentize(black, 0.4);
	z-index: 1000;
	display: flex !important;
	justify-content: space-around;
	align-items: center;

	.header-group {
		display: flex;
		place-content: space-between;

		span {
			font-size: 20px;
			font-weight: bold;
		}

		i {
			position: relative;
			top: 10 !important;
			right: 10 !important;
		}

		.fa-times:hover {
			cursor: pointer;
		}
	}

	.modal {
		position: relative;
		top: 100;
		left: 100;
		background: $gray-lighter;
		width: calc(100% - 32px);
		padding: 16px 32px;
		display: block !important;
		height: auto;

		&.modal-lg {
			@media (min-width: 992px) {
				max-width: 800px;
				width: 800px;
			}
		}
	}

	.modal-novidades {
		position: relative;
		top: 100;
		left: 100;
		background: $gray-lighter;
		width: calc(50% - 32px);
		padding: 16px 32px;
		display: block !important;
		height: auto;
		max-height: 90vh;
		overflow-y: scroll;

		img {
			max-width: 100%;
		}

		.cjto-botoes {
			margin-bottom: 20px;
		}

		&.modal-lg {
			@media (min-width: 992px) {
				max-width: 800px;
				width: 800px;
			}
		}

		@media (max-width: 992px) {
			width: 80%;
		}

		@media (max-width: 576px) {
			width: 90%;
			padding: 15px 10px;
		}

		@media (max-width: 450px) {
			h2 {
				font-size: 1.3em;
			}
		}
	}
}
.modal-backdrop-n2 {
	z-index: 1050;
}

h4.data-novidade-popup {
	margin-top: 5px;
	margin-bottom: 10px;
	font-weight: normal;
	font-size: 14px;
}

.modal-90w {
	max-width: 90vw;

	@media (min-width: 2000px) {
		max-width: 60vw;
	}
}

.modal-header {
	.close {
		background-color: #fff;
		font-weight: bold;
		font-size: 20px;
		border: 0 !important;
	}
}

.modal-le,
.modal-vistoria,
.modal-demolicao {
	max-width: 1000px !important;
	left: -5px;

	.modal-content {
		background-color: #edeff1;
		border-radius: 0;

		.modal-body {
			padding: 30px;
		}
		.modal-header {
			padding: 10px 30px;

			.modal-title {
				font-weight: normal;
				// padding: 0 20px;
				line-height: 24px;
			}

			.close {
				padding: 0;
				margin: 0;
				border: none;
			}
		}
	}
}

.cjto-botoes {
	display: flex;
	justify-content: flex-end;
	margin-right: 15px;
	margin-top: 15px;
	flex-wrap: wrap;
}

.input-group-append {
	button {
		padding-left: 20px;
		padding-right: 20px;
		margin-left: 10px;
		margin-top: 5px;
		padding-bottom: 3px;
	}
}

.btn-expediente {
	font-size: 14px;
	height: 40px;
	display: inline-block;
	padding: 11.5px 8px;
	font-weight: 400;

	&:hover {
		background-color: #edeff1;
		-webkit-transition: background-color 0.5s;
		-o-transition: background-color 0.5s;
		transition: background-color 0.5s;
	}
}

.container-eu-modal {
	max-height: 60vh;
	overflow: auto;
}

.eu-modal {
	border-bottom: 1px solid #c5c5c9;
	max-height: 80%;

	button {
		font-size: 18px;

		&:hover {
			color: darken($primary-color, 5%);
		}
	}

	li {
		color: #555555;
		list-style: none;
	}
}

.clear-button {
	background: transparent;
	border: none;
	padding: 8px;
	position: absolute;
	right: 10px;
	top: 52px;

	.fa {
		position: relative;
		margin: 0;
	}
}

.clear-button .fa {
	position: relative;
	margin: 44px;
}

.control-label {
	margin-top: 20px;
}

// LABELS, INPUTS N' VALUES

label {
	font-size: 14px;
	font-weight: normal;
	color: $gray-dark;
	line-height: 1;
	display: block;
	margin: 0;
	letter-spacing: normal;
	text-transform: none;
	width: 100%;
}

.label {
	color: $gray-dark;
	line-height: 1;
	display: block;
	margin: 0;
	letter-spacing: normal;
	text-transform: none;
	width: 100%;

	&:not(h1):not(h2):not(h3):not(h4) {
		font-size: 14px;
		font-weight: normal;
	}

	.opt {
		color: $gray;
		margin-left: 8px;
		font-style: italic;
	}

	h2,
	h3 {
		margin: 0;
	}

	&.inline {
		display: inline-block;
		margin-right: 8px;
	}
}

.label-inline {
	color: $gray;
	line-height: 1;

	&:not(h1):not(h2):not(h3):not(h4) {
		font-size: 14px;
		font-weight: normal;
	}
}

.label-button-group {
	display: grid !important;
	align-items: center;
	grid-template-columns: auto max-content;
	min-height: 40px;

	.item-count {
		font-size: 14px;
		font-weight: normal;
		display: block;
		margin-top: 8px;

		@media (min-width: 700px) {
			margin-top: -8px;
			display: inline;
			margin-left: 16px;
		}
	}

	.btn-left,
	.btn-right {
		@include btn();
		border: none;
		height: 40px;
		margin-top: -8px;
		margin-bottom: -8px;
		margin-left: -28px;
		background-color: transparent;
		width: 28px;
		text-align: center;

		@media (min-width: 700px) {
			width: 40px;
			margin-left: -40px;
		}

		.fa {
			font-size: 20px;
		}

		&:hover {
			background-color: transparent;

			.fa {
				color: $gray-dark;
			}
		}
	}
}

.value {
	font-size: 22px;
	font-weight: 700;
	line-height: 1.4;
	margin-top: 0;
	margin-bottom: 0;

	@media (min-width: 700px) {
		font-size: 28px;
	}
}

input[type='text'],
input[type='date'],
input[type='number'],
input[type='tel'],
input[type='month'],
select,
textarea {
	display: block;
	width: 100%;
	height: 40px;
	background-color: $light;
	border: 2px solid #c5c5c9;
	border-radius: 2px;
	padding: 8px 16px;
	color: $gray-dark;

	&:hover {
		background-color: transparentize($light, 0.2);
	}

	&:focus {
		color: $dark;
		background-color: transparentize($light, 0.1);
	}
}

textarea {
	height: auto;
	line-height: 1.4;
}

input[type='radio'] {
	height: 14px;
	width: 18px;
	margin-right: 8px;
	cursor: pointer;

	&:focus {
		outline-offset: 4px;
	}
}

input[type='radio'] + .label {
	display: inline-block;
	color: $gray-dark;
	font-size: 16px;
	cursor: pointer;
}

textarea[readonly]:not(.ant-input),
textarea[readonly]:hover:not(.ant-input),
textarea[readonly]:focus:not(.ant-input),
input[readonly]:not(.ant-input),
input[readonly]:hover:not(.ant-input),
input[readonly]:focus:not(.ant-input) {
	color: $gray;
	background-color: $gray-lighter;
	// cursor: not-allowed;
}

.input-table {
	.input-table-header {
		display: none;
	}

	.input-row {
		margin-bottom: 8px;
	}

	.row-label {
		margin-bottom: 0;
		margin-top: 8px;
		font-weight: bold !important;
		color: $gray;
	}

	.input-group:not(:last-child) {
		margin-bottom: 16px;
	}

	@media (min-width: 510px) {
		.input-group:not(:last-child) {
			margin-bottom: 0;
		}

		.row-label {
			margin-bottom: 32px;
		}

		.input-row {
			.label {
				display: none;
			}
		}

		.input-table-header {
			display: flex;
			margin-bottom: 0;

			.label {
				display: block;
			}
		}
	}
}

.info {
	white-space: pre-wrap;
	text-align: justify;
}

.element-with-link {
	position: relative;

	a {
		position: absolute;
		right: 0px;
		top: -42px;
		font-size: 16px;
		padding: 12px 12px 8px 10px;
	}
}

.wrapper-class-name-readOnly {
	height: auto;
}

.toolbar-class-name-readonly {
	display: none;
}

.editor-class-name-readOnly {
	border: 0px solid #687c80;
	border-radius: 2px;
	padding: 20px;
	margin: 10px 0 30px;
	background: #ededee;
	color: #212529;
	width: 100%;
}

.wrapper-class-name {
	height: auto;
	display: block;
	border: 1px solid #687c80;
}

.toolbar-class-name {
	display: flex;
}

.editor-class-name {
	width: 100%;
	background-color: white; // rgba(255, 255, 255, 0.1);
	border-radius: 2px;
	padding: 8px 16px;
	color: #3a4548;
	height: 20em;
	overflow: auto;
}

.tipoLicencaExpressaRowContainerClass {
	margin-left: -15px;
}

#root_identificacao {
	margin-bottom: 16px;
}

#root_atividadeEmpreendimento_enderecoCdlList__title {
	margin-left: 16px;
}

#root__title {
	font-size: 1.8em;
	color: $gray-dark;
	align-items: center;
}

.form-group legend {
	font-size: 1.4em;
	color: darken($gray, 10%);
}

.options-conselhos {
	display: flex;
	padding: 16px 0px;
}

.calendar-no-border {
	border: 0px !important;
	width: fit-content !important;
}

.calendar-no-border:focus {
	outline: none;
	border-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.calendar-container {
	display: flex;
}

.label-enquadramento {
	display: flex;
	justify-content: space-between;
	font-size: 14pt;
	white-space: normal;
	padding: 5px 0px 0px 5px;
	color: black;
}

.option-enquadramento {
	display: flex;
	justify-content: space-between;
	font-size: 14pt;
	white-space: normal;
	padding: 5px 0px 0px 25px;
	cursor: pointer;

	&.selected {
		color: $pro-color;
	}

	&:hover {
		background-color: rgba(200, 200, 200, 0.9);
	}
}

.question-container {
	width: fit-content;
	min-width: 100%;
	margin-bottom: 20px;
	border: 1px solid grey;
}

.list-group-item {
	&.menor {
		padding: 0rem 1.25rem;
	}
}

.inline-input-radio-checkbox {
	margin: 0 20px 0 0;
	display: inline-grid;

	input[type='checkbox'],
	input[type='radio'] {
		--active: #275efe;
		--active-inner: #fff;
		--focus: 2px rgba(39, 94, 254, 0.3);
		--border: #bbc1e1;
		--border-hover: #275efe;
		--background: #fff;
		--disabled: #f6f8ff;
		--disabled-inner: #e1e6f9;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		height: 21px;
		outline: none;
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin: 0;
		cursor: pointer;
		border: 1px solid var(--bc, var(--border));
		background: var(--b, var(--background));
		transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

		&:after {
			content: '';
			display: block;
			left: 0;
			top: 0;
			position: absolute;
			transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
		}

		&:checked {
			--b: var(--active);
			--bc: var(--active);
			--d-o: 0.3s;
			--d-t: 0.6s;
			--d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
		}

		&:disabled {
			--b: var(--disabled);
			cursor: not-allowed;
			opacity: 0.9;

			&:checked {
				--b: var(--disabled-inner);
				--bc: var(--border);
			}

			& + label {
				cursor: not-allowed;
			}
		}

		&:hover {
			&:not(:checked) {
				&:not(:disabled) {
					--bc: var(--border-hover);
				}
			}
		}

		&:focus {
			box-shadow: 0 0 0 var(--focus);
		}

		&:not(.switch) {
			width: 21px;

			&:after {
				opacity: var(--o, 0);
			}

			&:checked {
				--o: 1;
			}
		}

		& + label {
			font-size: 14px;
			line-height: 21px;
			display: inline-block;
			vertical-align: top;
			cursor: pointer;
			margin-left: 4px;
		}
	}

	input[type='checkbox'] {
		&:not(.switch) {
			border-radius: 7px;

			&:after {
				width: 5px;
				height: 9px;
				border: 2px solid var(--active-inner);
				border-top: 0;
				border-left: 0;
				left: 7px;
				top: 4px;
				transform: rotate(var(--r, 20deg));
			}

			&:checked {
				--r: 43deg;
			}
		}

		&.switch {
			width: 38px;
			border-radius: 11px;

			&:after {
				left: 2px;
				top: 2px;
				border-radius: 50%;
				width: 15px;
				height: 15px;
				background: var(--ab, var(--border));
				transform: translateX(var(--x, 0));
			}

			&:checked {
				--ab: var(--active-inner);
				--x: 17px;
			}

			&:disabled {
				&:not(:checked) {
					&:after {
						opacity: 0.6;
					}
				}
			}
		}
	}

	input[type='radio'] {
		border-radius: 50%;

		&:after {
			width: 19px;
			height: 19px;
			border-radius: 50%;
			background: var(--active-inner);
			opacity: 0;
			transform: scale(var(--s, 0.7));
		}

		&:checked {
			--s: 0.5;
		}

		+ label {
			position: relative;
			margin-top: -22px;
			margin-left: 26px;
			cursor: pointer;
		}
	}
}

// .checkbox-container {
// 	padding: 10px;
// 	border: 1px solid;
// 	margin: 10px;

// 	.checkbox-label {
// 		font-size: 18px;
// 	}

// 	.checkbox-input {
// 		color: orange;
// 		padding: 10px;
// 		margin-right: 10px;
// 	}
// }

.checkbox-input {
	display: flex !important;
	align-items: baseline;
	align-self: baseline;
	align-content: flex-end;
	padding: 10px;
	margin-right: 10px;

	input {
		margin-right: 15px;
		transform: scale(1.6);

		&:focus {
			box-shadow: 0 0 4px darken($primary-color, 15%);
			outline: 0 none;
		}
	}

	label {
		font-size: 13pt !important;
		// padding-left: 1.5em !important;
	}
}

.container-checkboxes {
	padding: 8px 0;
}

.label-checkbox {
	display: flex;
	font-size: 13pt !important;
	align-items: baseline;

	&.formulario-uap {
		font-size: 14px !important;
	}

	&.subitem {
		padding-left: 32px;
	}

	.checkbox-input {
		display: flex !important;
		align-items: baseline;
		align-self: baseline;
		align-content: flex-end;
		padding: 10px;
		margin-right: 1rem;
		margin-bottom: 1rem;

		transform: scale(1.6);

		&[type='checkbox']:focus {
			box-shadow: 0 0 4px darken($primary-color, 15%);
			outline: 0 none;
		}
	}
}

.atividade-item {
	display: flex;
	flex: 1 0;
	margin-left: -15px;
	margin-right: -15px;

	&.separador {
		border-bottom: 1px solid;
	}

	.atividade {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-content: flex-start;
		padding: 0px 0px;

		* {
			padding: 3px;
		}
	}

	.side-toolbar {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: #edeff1;
		border-left: 1px solid;

		i {
			margin: 5px;
			cursor: pointer;

			&.fa-plus {
				color: lighten(green, 10%);
			}

			&.fa-trash {
				color: maroon;
			}
		}
	}
}

.subunidade-area {
	display: flex;
	justify-content: space-between;
	flex: 1;

	.campos {
		display: flex;
		flex: 1 1;

		.campo {
			display: flex;
			flex: 1;

			&:first-child {
				margin-right: 5px;
			}

			&:last-child {
				margin-left: 5px;
			}
		}
	}

	.side-toolbar {
		display: flex;
		flex-direction: column;
		justify-content: center;

		i {
			margin: 5px;
			cursor: pointer;

			&.fa-plus {
				color: lighten(green, 10%);
			}

			&.fa-trash {
				color: maroon;
			}
		}
	}
}

// Bloco lista anexos relacionados à seleção de indicadores

.bloco-lista-anexos {
	margin-top: 30px;
	font-size: 16px;
	color: #212529;
	background-color: #eee;
	padding: 24px 20px;

	.fa {
		color: #ebb134;
		font-size: 30px;
		float: left;
		margin-top: 5px;
		margin-right: 18px;
	}

	ul {
		margin-top: 20px;
		padding-left: 65px;
	}
}

.box-complementacao {
	border: 1px solid;
	padding-bottom: 10px;
	margin: 0 16px 20px 16px;
}

.link-upload-destaque {
	background-color: #e4e4e4;
	padding: 30px 20px;
	font-size: 20px;
	margin-top: 20px;
	line-height: 22px;

	&:before {
		content: '\f062 \00a0 \00a0 \f062 \00a0 \00a0 \f062';
		font-family: FontAwesome;
		color: #00afa8;
		display: block;
		margin-bottom: 10px;
	}

	&:after {
		content: 'O documento (PDF) não pode ser modificado (dividir, alterar o conteúdo etc.), pois a assinatura do revisor perderá a validade. Apenas assinar digitalmente.';
		color: #565555;
		display: block;
		font-size: 16px;
		font-style: italic;
		line-height: 18px;
		margin-top: 10px;
	}
}

//Checkboxes

.checkbox {
	> [type='checkbox']:not(:checked),
	> [type='checkbox']:checked {
		/* Esta parte esconde a checkbox original */
		position: absolute;
		left: -9999px;

		+ label {
			position: relative;
			padding-left: 32px;
			cursor: pointer;
			padding-top: 4px;

			//Nestes pseudoelementos serão desenhados os estados do checkbox
			&:before,
			&:after {
				position: absolute;
				left: 0;
				top: 2px;
				width: 24px;
				height: 24px;
			}

			//Em :before é desenhada a caixa da checkbox (not checked)
			&:before {
				content: '';
				border: 2px solid #c5c5c9;
				background: #fff;
			}

			&:hover:before {
				border-color: #09afa7;
			}

			//Em :after é desenhado o checkbox marcado (checked)
			&:after {
				font-family: FontAwesome;
				content: '\f00c';
				font-size: 18px;
				line-height: 1;
				color: #09afa7;
				padding: 2px 3px;
				transition: all 0.2s;
			}
		}
	}

	//Mudar cor da borda quando o checkbox está marcado
	[type='checkbox']:checked {
		+ label {
			&:before {
				border: 2px solid #09afa7;
			}
		}
	}

	/* checked mark aspect changes */
	[type='checkbox']:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}

	[type='checkbox']:checked + label:after {
		opacity: 1;
		transform: scale(1);
	}

	/* disabled checkbox */
	[type='checkbox']:disabled:not(:checked) + label:before,
	[type='checkbox']:disabled:checked + label:before {
		border-color: #c5c5c9;
		background-color: #ededee;

		&:hover {
			border-color: #c5c5c9;
		}
	}

	[type='checkbox']:disabled:checked + label:after {
		color: #c5c5c9;
	}

	[type='checkbox']:disabled + label {
		color: #919198;
		cursor: not-allowed;
	}

	/* accessibility */
	[type='checkbox']:checked:focus + label:before,
	[type='checkbox']:not(:checked):focus + label:before {
		border-color: #09afa7;
		box-shadow: 0 0 5px #09afa7;
		outline: 0 none;
	}
}

// página validacao documentos

.texto-apoio {
	color: #525257;
	font-size: 1.15em;
	margin: 2.5em -15px 1em -15px;
}

input.input-com-botao {
	max-width: calc(100% - 250px);
	float: left;
}

.botao-apos-input {
	float: right;
	border-radius: 0;
	width: 250px;
	margin-top: 6px;
	padding: 10px 16px 9px 16px;
}

.secao-verificacao-documentos {
	max-width: 800px;
}

.bloco-sem-resultado {
	margin-top: 30px;
	font-size: 16px;
	color: #212529;
	background-color: #eee;
	padding: 24px 20px;

	.fa {
		color: #ebb134;
		font-size: 30px;
		float: left;
		margin-right: 18px;
	}
}

.inline-buttons {
	display: flex;
	justify-content: space-between;
	height: 40px;
	margin-top: 34px;
	gap: 10px;
	margin-left: 10px;
	margin-right: 0px;
}

.titulo-linha-ajuda {
	font-size: 1.4em;
	font-weight: 700;
	color: #525257;
}

.conteudo-ajuda {
	font-size: 1.1em;
	margin-bottom: 3em;
}

.linha-servicos.border,
.home-link-wrapper.border {
	border: 3px solid #dee2e6 !important;
}

.linha-servicos {
	display: flex !important;
	flex-direction: row !important;
	flex-wrap: wrap;
	width: 100%;
}

.coluna-servicos {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;

	img {
		width: 97px;
		margin: 0 auto;
	}
}

.paragrafo-apoio-servicos {
	font-size: 1em;
	font-style: italic;
	text-align: left;
	line-height: 1.15em;
	font-weight: 300;
	margin-left: 15px;
	margin-top: 12px;
}

.coluna-servicos-1 {
	max-width: 140px;
}

.coluna-servicos-2 {
	text-align: left;

	h2 {
		font-size: 1.4em;
	}
}

.licencas-na-hora {
	padding-right: 15px !important;
}

.flag {
	border-left: 16px solid $primary-color;
	border-right: 16px solid $primary-color;
	border-bottom: 15px solid transparent;
	height: 45px;
	position: absolute;
	margin-left: 330px;
}

.flag2 {
	border-left: 18px solid #dee2e6;
	border-right: 18px solid #dee2e6;
	border-bottom: 16px solid transparent;
	height: 50px;
	position: absolute;
	margin-left: 328px;
}

@media (max-width: 1199.98px) {
	.flag {
		margin-left: 280px;
		border-left: 12px solid #09afa7;
		border-right: 12px solid #09afa7;
		border-bottom: 12px solid transparent;
		height: 36px;
	}

	.flag2 {
		margin-left: 278px;
		border-left: 14px solid #dee2e6;
		border-right: 14px solid #dee2e6;
		border-bottom: 13px solid transparent;
		height: 41px;
	}
}

.rodape-capa {
	background-color: #525257;
	color: #fff;
	padding-top: 20px;
	padding-bottom: 20px;
}

.endereco-rodape {
	float: left;
	width: 650px;

	img {
		float: left;
		margin-right: 20px;
	}

	a {
		color: #fff !important;
		text-decoration: underline;
		font-style: italic;
		font-weight: 400;
		letter-spacing: 0.02em;

		&:hover {
			opacity: 0.5;
		}
	}

	p {
		padding-top: 4px;
		margin-bottom: 0.5rem;
	}

	.detalhe-endereco {
		font-size: 14px;
		opacity: 0.9;
		line-height: 17px;
		margin-top: 5px;
	}
}

.contatos-rodape {
	text-align: right;

	a {
		color: #fff !important;
		text-decoration: underline;
		font-style: italic;
		font-weight: 400;
		letter-spacing: 0.02em;

		&:hover {
			opacity: 0.5;
		}
	}

	p {
		margin-bottom: 0;
	}

	.fa {
		color: #ffdf57;
		margin-right: 8px;
	}

	.fa-phone {
		font-size: 20px;
	}
}

.container-capa {
	min-height: calc(100% - 220px) !important;
	margin-bottom: -152px;
	padding-bottom: 152px;
}

@media (max-width: 1199.98px) {
	.contatos-rodape {
		a {
			word-break: break-all;
		}
	}
}

@media (max-width: 991.98px) {
	.home-link-wrapper {
		margin-bottom: 20px;
		min-height: 100%;

		h2 {
			font-size: 1.4em;
		}
	}

	.box-servicos {
		padding-right: 15px !important;
	}

	.linha-servicos.border,
	.home-link-wrapper.border {
		margin-right: 15px;
	}

	.flag2 {
		display: none;
	}

	.flag {
		margin-left: 85%;
	}

	.linha-servicos {
		display: block !important;
	}

	.licencas-na-hora {
		padding-right: 0 !important;
	}

	.coluna-servicos-1 {
		max-width: 100%;

		img {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

	.coluna-servicos-2 {
		text-align: center;
	}

	.paragrafo-apoio-servicos {
		text-align: center;
	}

	.rodape-capa {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.endereco-rodape {
		float: none;
		width: 100vw;
	}

	.contatos-rodape {
		float: none;
		width: 100%;
		text-align: left;
		margin-top: 30px;
		margin-left: 80px;
	}
}

@media (max-width: 576px) {
	.endereco-rodape {
		max-width: 350px;

		img {
			margin-bottom: 50px;
		}
	}

	.contatos-rodape {
		margin-top: 20px;
		margin-left: 0;
	}
}

.debug-message {
	color: grey;
	font-size: 9pt;
	white-space: nowrap;
}

.tracker-geral {
	margin: 20px;
}

.tracker-andamento {
	cursor: pointer;
}

.tracker-ausente {
	cursor: none;
	opacity: 0.5;
}

.tracker-finalizado {
	cursor: pointer;
	color: #09afa7;
}

.tracker-indeferido-expirado {
	cursor: pointer;
	color: red;
}

.card-header {
	color: #09afa7;

	background-color: whitesmoke;
	padding: 0.75rem 2rem 0.75rem 1rem;
	border-bottom: 2px solid #fff;
	font-weight: bold;
	font-family: 'Lato', Verdana, sans-serif;
	@media (min-width: 768px) {
		font-size: 1.125em;
	}
	&:hover {
		cursor: pointer;
		background-color: #09afa7;
		color: white;
	}
}

.card-header-selecionado {
	background-color: #09afa7;
	color: white;
}

.card-borderless {
	border: none;
}

.titulo-filtros-processos {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.85em;
}

.btn-filtros {
	padding: 4px 8px;
	font-size: 1em;
}

.filtro-processos-concluidos {
	padding-left: 15px;
	padding-right: 15px;
}

.filtro-processos-andamento {
	padding-right: 15px;
}

.btn-limpar-filtros {
	color: #ad144f;
	background-color: #fff;
	border: 0;

	@media (max-width: 1180px) {
		padding-left: 15px;
	}
}

.btn-limpar-filtros::after {
	content: '\f057';
	font-family: 'FontAwesome';
	margin-left: 5px;
}

.input-status-processos input {
	height: 28px !important;
	width: 200px !important;
}

@media (max-width: 768px) {
.input-status-processos {
		padding-left: 15px !important;
		padding-right: 15px !important;
		width: 100%;
		margin-top: 10px;

		input {
		width: 100% !important;
		}
	}

.col-3.titulo-filtros-processos {
		flex: none;
		max-width: 100%;
		margin-bottom: 10px;
	}
.filter.col-9 {
		flex: none;
		max-width: 100%;
	}
}

.linha-filtros {
	padding-bottom: 15px;
	border-bottom: 1px dashed #c0c0c0;

	i {
		top: -2px;
	}
}

.status {
	display: flex;
	align-items: baseline;
	gap: 10;

	margin-top: 4px;
	margin-left: 16px;
	color: white;
	border-radius: 12px;
	padding: 0 8px;
	// white-space: nowrap;
	background-color: grey;

	.fa {
		color: white;
		margin-right: 8px;
	}

	&.deferido {
		background-color: #00ccaa;
	}
	&.concluido,
	&.confirmado {
		background-color: #5386e5;
	}
	&.expirado,
	&.indeferido,
	&.recusado,
	&.anulado {
		background-color: #595959;
	}
	&.registro-criado,
	&.etapa-criada,
	&.em-andamento {
		background-color: #1b8dde;
	}
	&.complementacao {
		&.error {
			background-color: darken(red, 25%);
		}
		&.warning {
			background-color: #f5dd38;
			color: #4a4a4a;

			.fa {
				color: #4a4a4a;
			}
		}
	}

	@media (min-width: map-get($grid-breakpoints, 'md')) {
		flex-wrap: nowrap;
	}
}

.mytooltip {
	.tooltip-inner {
		max-width: 320px !important;
		text-align: left;
	}
}

.licencas-expressas-obs-tipo-form {
	font-size: 10pt;
	font-style: italic;
}

.requerimento-indices {
	h2 {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}

.legenda-mapa {
	box-sizing: content-box;
	width: 200px;
	background-color: #6c757d;
	color: #fff;
	padding: 6px;
	text-align: center;
	position: relative;
	border-radius: 4px;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
	font-style: italic;

	p {
		line-height: 20px;
	}

	.close {
		position: absolute;
		top: 5px;
		right: 5px;
		background-color: #3aa3cc28;
	}
}

.d-flex {
	display: flex;
}

.gap-10 {
	gap: 10px;
}

.flex-1 {
	flex: 1;
}

.flex-column {
	flex-direction: column;
}

.flex {
	display: flex !important;

	&-1 {
		flex: 1;
	}
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.space-between {
	justify-content: space-between;
}

.baseline {
	align-items: baseline;
}

.modal-map {
	height: auto;
	// width: 90vw;
}

.map-sideview {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 16vw;
	max-height: 70vh;
	overflow-y: auto;
	padding-right: 7px;
	// border: 1px solid black;

	@media (max-width: 768px) {
		width: auto;
		max-height: 100%;
		overflow-y: hidden;
	}

	.map-geometry {
		border: 3px solid #d8d8d8;
		padding: 8px;
		cursor: pointer;

		.map-geometry-type {
			font-size: 0.8em;
			font-weight: bold;
			text-transform: uppercase;
			color: #212529;
			border-bottom: 1px solid #d8d8d8;
			margin-bottom: 8px;
		}

		.map-enderecos-list {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: 0;
		}
	}
}

.map-geometry-type {
	font-weight: bold;
}

.map-enderecos-list {
	border-bottom: 1px solid #0000001f;
	width: 60vw;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
}

.pigeon-overlays {
	position: relative;
	z-index: 9999;

	.container-info-pin {
		z-index: 10000;
		position: absolute;
		top: 5px;
		right: 5px;
	}
}

.modal-componente-mapa {
	max-height: calc(80vh - 500px);

	.modal-header {
		font-size: 21px;
		margin-top: 20px;
		padding: 0 30px !important;

		.modal-title {
			font-size: 21px;
		}
	}
	.toolbar {
		display: block;

		.btn-primary {
			margin-right: 16px;
		}
	}

	.toolbar.secondary .btn-primary {
		@media (max-width: 860px) {
			margin-bottom: 10px;
		}
	}

	.toolbar-botoes {
		background-color: #edeff1;
		padding-top: 20px;
		// border-top: 1px solid #dee2e6;
		bottom: 0;
		z-index: 1000;
	}

	.modal-body {
		padding: 0px 30px 10px 30px !important;
	}

	.btn-primary.disabled,
	.btn-primary:disabled {
		color: #fff;
		background-color: #80a7a4;
		border-color: #80a7a4;
	}

	.btn-secondary {
		background-color: #ffffff40;
		border: 2px solid #28b0a7;
		margin-right: 16px;
		color: #24968f;
		padding: 6px 10px;
		font-size: 0.95em;
	}

	.btn-primary {
		padding: 6px 10px;
		font-size: 0.95em;
	}

	.salvarAlteracoes h3 {
		margin-top: 20px;
		font-size: 0.95em;
		margin-bottom: -20px;
	}
}

.marcador-componente-mapa {
	font-size: 24pt;
	color: #09afa7;
	// background-color: #fff;
	text-align: center;
	text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff,
		-1px 1px #fff;
	margin-left: -5px;
	top: -12px;
	position: relative;
}

.numeracao-marcador-componente-mapa {
	font-size: 18pt;
	font-weight: 700;
	color: #4e5051;
	font-family: 'Lato', 'sans-serif';
	// top: 8px;
	position: relative;
}

.cancelarAlteracoes {
	margin-top: 30px;
	border-top: 2px solid gainsboro;
	padding-top: 20px;
}

.info-pin {
	background-color: #2185d0;
	text-align: left;
	white-space: nowrap;
	padding: 6px 10px;
	border: 0;
	border-radius: 0;
	position: relative;
	font-size: 18px;
	color: #fff;
	font-size: 0.95em;
	margin-right: 10px;

	&:hover {
		cursor: pointer;
		background-color: #145f97;
	}

	.close {
		position: relative;
		float: right;
		right: 0;
		margin-left: 10px;
	}
}

.ultimo-evu-valido {
	margin-top: 20px;
	margin-left: 40px;

	.title {
		font-size: 1rem;
		font-weight: 700;
		color: $gray-600;
		margin-top: 30px;
		margin-bottom: 15px;
	}

	.grid {
		display: grid;
		grid-template-columns: 150px 1fr;

		.campo {
			font-weight: 700;
			color: $gray-600;
			font-size: 0.9rem;
		}

		.valor {
			font-weight: 400;
			color: $gray-600;
			font-size: 0.9rem;
		}
	}

	span {
		border-bottom: 1px solid #dddcdc;
		padding-bottom: 6px;
		padding-top: 6px;
	}

	.warning-message span {
		border-bottom: 0;
	}
}

.botao-link {
	background-color: darken($primary-color, 10%);
	padding: 15px;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
	outline: none;
	transition: 0.05s all;

	&:active {
		transform: scale(0.98);
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	}

	.label {
		font-size: 1.1rem;
		color: $light;
	}
}

.modal-demolicao .modal-title {
	font-size: 31px;
	margin-top: 18px;
	margin-bottom: 12px;
	font-weight: 600;
	display: block;
	line-height: 1;
}

.doc-certidao-demolicao {
	fieldset {
		background-color: #fff;
		border: 1px solid #dfe2e6;
		padding: 20px;
		margin-top: 1rem;

		div.titulo-certidao-demolicao {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 18px;
			color: #687c80;
			margin-bottom: 2rem;
			margin-top: 1rem;
		}
	}

	p.observacoes {
		border-left: 2px solid #687c80;
		padding-left: 1rem;
		font-style: italic;
		max-width: 820px;
		margin-left: 10px;
	}

	label {
		white-space: nowrap;
	}
}

.container-header {
	width: 100%;
}

#concordo {
	margin-left: -1rem !important;
}

.form-group-select-subunidades {
	max-width: 450px;
	margin-left: 10px;

	label {
		margin-bottom: 8px;
	}
}

.cartas-habitacao {
	margin-bottom: 40px;
	.pesquisas {
		padding: 20px 0 0 0;

		.col-md-8,
		.col-md-6 {
			padding-left: 0px;
		}
	}

	.nav-item.tab {
		cursor: pointer;
		padding: 10px;
		border: 1px solid $gray-light;
		border-radius: 10px 10px 0px 0px;
		cursor: pointer;

		&.active {
			background-color: $gray-light;
		}
	}
}

.cartas {
	.expediente {
		margin-top: 30px;
		width: 100%;
		border: 1px solid $gray-light;
		padding: 20px;
		&-header {
			display: flex;
			gap: 30px;
			align-items: baseline;
			padding: 0px 0px 20px;
			border-bottom: 1px solid $gray-light;
			.particula {
				.label {
					font-size: 1.1rem;
					color: $gray;
					width: 100px;
				}
				.value {
					font-size: 1.4rem;
					color: $gray-dark;
				}
			}
		}

		.vistorias {
			margin-top: 25px;
			.vistoria {
				display: flex;
				align-items: baseline;
				padding: 10px 0px;

				cursor: pointer;
				&:hover {
					background-color: #f6f6f6;
				}

				&::before {
					content: '\f019';
					font-family: 'FontAwesome';
					margin-right: 10px;
					background: #f6f6f6;
					color: #09afa7;
					border-radius: 50%;
					display: inline-block;
					padding: 6px 10px;
					top: 0px;
					position: relative;
					width: 35px;
					height: 35px;
				}
			}
		}

		.cadastros {
			display: flex;
			flex-direction: column;
			margin-top: 25px;

			.endereco {
				display: flex;
				align-items: baseline;
				.valor {
					font-weight: 700;
				}
			}
			.cadastro {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				padding: 10px 0px;
				cursor: pointer;

				.carta {
					display: flex;

					&:hover {
						background-color: #f6f6f6;
					}

					&::before {
						content: '\f019';
						font-family: 'FontAwesome';
						margin-right: 10px;
						background: #f6f6f6;
						color: #09afa7;
						border-radius: 50%;
						display: inline-block;
						padding: 6px 10px;
						top: 9px;
						position: relative;
						width: 35px;
						height: 35px;
					}
				}
			}
		}
	}
}

.checkbox-component-warning {
	display: block;
	margin-left: 27px;
	margin-top: -15px;
	margin-bottom: 10px;
	color: grey;
	font-size: 0.9em;
	text-align: justify;
	max-width: 85%;
}

.planilha-udri-checkbox-container {
	display: flex;
	justify-content: space-between;

	.celula {
		display: grid;
		flex: 0.5;
	}
}

.rc-calendar-range {
	width: 503px;
}

.rc-time-picker-panel-narrow {
	max-width: 114px;
}

.checkbox-component-novo-layout-observacao-option {
	display: block;
	color: $gray;
	text-align: justify;
	font-style: italic;
	font-size: 0.9rem;
	margin-bottom: 1rem;
}

.blink {
	animation: blinker 1s linear infinite;
	font-size: 1.2rem;
	font-weight: 700;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.bloco-servico-contato {
	background-color: #ededee;
	padding: 30px;
}

.p-relative {
	position: relative;
}

.links-help-fields {
	display: flex;
	flex-direction: column;
	margin-left: 5px;
}

.tippy-help-fields {
	position: absolute;
	right: 16px;
	top: 12px;
	opacity: 0.5;
}

.btn-disabled {
	background-color: #d3d3d3;
	color: #a9a9a9;
	cursor: not-allowed;

	padding-left: 16px;
	padding-right: 16px;
	font-size: 18px;
	min-width: 44px;
}

.numero-enquadramento {
	display: flex;
	justify-content: center;
	align-items: center;
}

.enquadramento-container.somente-docs {
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	column-gap: 3rem;

	.label-enquadramento {
		font-size: 15px;
		font-weight: bold;
		padding-bottom: 4px;
	}

	.question-container {
		background-color: transparent !important;
	}

	.option-enquadramento {
		font-size: 12pt;
		padding: 2px 0 2px 15px;

		&.selected {
			color: $primary-color;
		}

		.numero-enquadramento {
			display: flex;
			justify-content: center;
			align-items: center;
			color: $primary-color !important;
			padding: 4px 0 0 4px;
		}
	}

	.enquadramento-docs {
		h4 {
			margin-top: 8px;
		}

		ul {
			padding-left: 25px;
		}
	}
}

.rascunho {
	margin-left: 0;
}

.fa-icone-rascunho {
	margin-right: 1rem;
}
