.template-form {

  .enum {
    label .enum-number {
      font-weight: bold;
    }

    label .enum-number:after {
      content: ' - ';
    }
  }

  .sub-enum {
    margin-left: 1rem;
  }

  .required {
    color: red;
  }

  fieldset {
    margin-top: 1rem;

    legend {
      text-decoration: underline;
    }
	}

	.expediente-unico-field {
		width: 100%;
	}
	.cdl-field {
		width: 100%;
	}
	.art-field {
		width: 100%;
	}
}
