.modal-exp-search {
	.control-label {
		margin-bottom: 35px;
		margin-top: 5px;
		position: relative;
		.exp-query-sample {
			background-color: #dddddd;
			padding: 4px 8px 4px 8px;
		}
		.exp-query-info {
			position: absolute;
			top: 25px;
			left: 0px;
			color: #9f9f9f;
			font-size: 14px;
		}
	}
	.eu-modal {
		position: relative;
		&:hover {
			background-color: #e3e3e3;
			cursor: pointer;
		}
		.btn-expediente {
			background-color: unset;
		}
		.btn-link {
			&:hover {
				text-decoration: unset;
			}
		}
	}
}

.expediente-enderecos-fieldbox {
	display: grid;
	position: relative;
	grid-template-columns: 50% 50%;

	.expediente-unico-field {
		background-color: #eee;
		padding: 10px 10px 25px 10px;
		.expediente-unico-numero,
		.expediente-unico-busca {
			.btn-link {
				font-size: 18px;
				top: -12px;
				position: relative;
				margin-left: unset;
				i {
					margin-right: 5px;
				}
			}
		}
		.expediente-unico-busca {
			.btn-primary {
				vertical-align: baseline;
				position: relative;
				height: 39px;
			}
			.expedientes-warning-busca {
				padding: 7px 0;
				color: red;
				display: inline-block;
				position: relative;
				top: -12px;
				margin-right: 8px;
				i {
					margin-right: 8px;
				}
			}
		}
		.exp-area-privativa {
			width: 112px;
		}
		.endereco-btn-wrapper {
			display: block;
			position: relative;
			&.reposition-btn {
				top: -10px;
				left: 10px;
			}
		}
		.expediente-unico-remove {
			button {
				position: absolute;
				font-size: 24px;
				top: 0;
				right: 0;
				z-index: 1;
			}
		}
		.exp-busca-wrapper {
			.exp-busca-input {
				width: 100%;
			}
		}
		.fieldbox-title {
			font-size: 18px;
			color: #555;
			letter-spacing: normal;
			text-transform: none;
			font-weight: 500;
			display: block;
			width: 100%;
			text-align: center;
			padding-top: 30px;
			span {
				text-decoration: underline;
			}
		}
		.endereco-btn-wrapper {
			text-align: center;
			.btn {
				font-size: 20px !important;
				font-weight: 500;
				position: relative;
				padding-left: 7px;
				i {
					position: absolute;
					left: -16px;
					top: 3px;
				}
			}
			.text-left {
				text-align: left;
			}
			.btn-wrapper-link {
				display: block;
				text-align: center;
				position: relative;
				&.btn-link-reposition {
					top: -9px;
				}
			}
		}
		.endereco-list-field {
			font-size: 18px;
			color: #555;
			letter-spacing: normal;
			text-transform: none;
			font-weight: 400;
			display: block;
			width: 100%;
			ul {
				padding-left: unset;
				li {
					display: block;
					width: 100%;
					height: 40px;
					background-color: #e5e5e500;
					border: 2px solid #b1b1b5;
					border-radius: 2px;
					padding: 8px 16px;
					color: #292929;
					list-style-type: none;
				}
			}
		}
	}

	.cdllist-field {
		background-color: #eee;
		grid-template-columns: auto;
		border-left: 5px solid #fff;
		position: relative;
		padding: 10px 10px 25px 10px;
		&.cdllist-field-selected {
			padding: 0px 10px 25px 10px;
		}
		.fieldbox-divider {
			position: absolute;
			top: 57px;
			left: -28px;
			width: 50px;
			background-color: #09afa7;
			height: 50px;
			border-radius: 50%;
			padding: 5px 11px;
			font-size: 24px;
			font-weight: bolder;
			color: #fff;
		}
		&.skeleton {
			.fieldbox-divider {
				border: 8px solid #cdcdcd;
				border-right-color: orange;
				animation: l2 1s infinite linear;
				background-color: #adadad;
				span {
					display: none;
				}
			}
		}
		.expediente-unico-remove {
			vertical-align: top;
			button {
				position: absolute;
				font-size: 24px;
				top: 0;
				right: 0;
				z-index: 1;
			}
		}
		.fieldbox-title {
			font-size: 18px;
			color: #555;
			letter-spacing: normal;
			text-transform: none;
			font-weight: 500;
			display: block;
			width: 100%;
			text-align: center;
			padding-top: 30px;
			span {
				text-decoration: underline;
			}
		}
		.endereco-btn-wrapper {
			text-align: center;
			.btn {
				font-size: 20px !important;
				font-weight: 500;
				position: relative;
				padding-left: 7px;
				i {
					position: absolute;
					left: -16px;
					top: 3px;
				}
			}
		}

		.cdl-action-btns {
			vertical-align: middle;
		}
		.control-label-aux {
			margin-bottom: 7px;
		}
		.btn-link,
		.btn-link-actions {
			font-size: 16px;
		}
		.btn-link-actions {
			position: relative;
			margin-top: unset;
			&.btn-link-right-space {
				margin-right: 15px;
			}
		}
		.btn-usar-address,
		.btn-descartar-address {
			padding: 0 0 0 21px;
			i {
				position: absolute;
				top: 3px;
				left: 0px;
				font-size: 18px;
			}
		}
		.btn-descartar-address {
			top: -5px;
		}
		.btn-link-add {
			margin-top: 10px;
		}
		.cdl-outter-wrapper {
			padding: 10px 10px;
		}
		.cdl-wrapper-lista-aux {
			margin-bottom: 25px;
		}
		.cdl-field {
			padding-bottom: 20px;
			position: relative;
			.cdl-field-logradouro,
			.cdl-field-numero {
				margin-top: unset;
				input {
					margin-top: unset;
				}
			}
		}
		.list-group-item {
			background-color: #eeeeee;
			border: 1px solid #bdbdbd !important;
		}
	}

	&.exp-selected {
		grid-template-columns: 100% 0px;
		.cdllist-field {
			visibility: hidden;
			.fieldbox-divider,
			.fieldbox-title {
				display: none;
			}
		}
	}
	&.address-selected {
		grid-template-columns: 0px 100%;
		.expediente-unico-field {
			visibility: hidden;
		}

		.cdllist-field {
			border-left: unset !important;
		}
	}
}
.control-label-aux {
	font-size: 18px;
	color: #555;
	margin: 0;
	letter-spacing: normal;
	text-transform: none;
	font-weight: 400;
	display: block;
	width: 100%;
}
.no-padding {
	padding: unset !important;
}

.skeleton {
	animation: skeleton-loading 0.5s linear infinite alternate;
}

@keyframes skeleton-loading {
	0% {
		background-color: #eeeeee;
	}
	100% {
		background-color: #d9d9d9;
		color: #383a3f;
	}
}

@keyframes l2 {
	to {
		transform: rotate(1turn);
	}
}
