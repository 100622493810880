.form-vistoria-especifico {
	.required,
	:required {
		color: red;
	}

	.vistoria-card {
	background-color: #fff;
    border: 1px solid #dfe2e6;
    padding: 20px;
    margin-top: 1rem;

		&.erros {
			box-shadow: 0 4px 8px 0 rgba(240, 62, 62, 0.4);
		}

		&:first-child {
			margin-top: 0;
		}
	}

	fieldset {
		// margin-top: 1rem;
		// margin-bottom: 1rem;

		legend {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 18px;
			color: #687c80;
			margin-bottom: 12px;
		}

		.legend_secundaria {
			font-style: italic;
			color: #505f62;
			margin: 30px 0 0px -26px;
			font-size: 16px;
			font-weight: 400;
			background: #e4e5e5;
			padding: 2px 10px 2px 30px;
			display: inline-block;
		}
	}

	.vistoria-form-list > .vistoria-form-list-item {
		// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		border: 3px solid rgba(0, 0, 0, 0.2);
		// background-color: rgba(233, 235, 236, 0.801);
		transition: 0.3s;
		margin: 1rem 0 0.5rem 0;
		padding: 0 0 1rem 0;
	}
}

.vistoria-card .control-label {
    margin-top: 0;
}

.modal-vistoria .modal-title {
    font-size: 31px;
    margin-top: 18px;
    margin-bottom: 12px;
    font-weight: 600;
    display: block;
    line-height: 1;
}

.numero-pranchas .form-check-inline {
    white-space: nowrap;
}

.perguntas.metragem.descricao-pranchas {
    margin-top: 10px;
}

.endereco-obra-vistoria {
	margin-bottom: 10px;
    background-color: #f7f7f7;
    padding: 15px 20px;
}

.endereco-obra-vistoria .cdl-field label.control-label {
    font-size: 14px;
    font-weight: normal;
    color: #7d7f80;
    line-height: 1;
    display: block;
    margin: 0;
    letter-spacing: normal;
    text-transform: none;
    width: 100%;
}

.endereco-obra-vistoria .cdl-field .col-md-8 {
	padding-left: 0;
}

.endereco-obra-vistoria .cdl-field .col-md-4 {
	padding-right: 0;
}

.condicionante-resposta {
    border-left: 2px dotted #687c8040;
	margin: 20px 0 20px 30px;
	padding: 10px 0px 10px 12px;
}

.condicionante-resposta .d-flex {
    display: block !important;
}

.vistoria-condicionante-texto {
		font-size: 14px;
}

.vistoria-condicionante-numero-sei {
	label {
	font-size: 14px !important;
	color: #7d7f80 !important;
	}
}

.data-projeto-licenca {
	input {
	position: relative;
    right: -36px;
	}
	.btn-calendar {
	position: relative;
	bottom: 0;
	}
}

.vistoria-card {
.pergunta.metragem {
	select.form-control {
		padding: 8px 16px;
	}
}
}

