.form-certificacao-sustentavel {
	.required,
	:required {
		color: rgb(196, 45, 45);
	}

	.card {
		margin-bottom: 12px;

		.card-header {
			display: grid;

			color: #09afa7;
			background-color: whitesmoke;
			padding: 0.75rem 2rem 0.75rem 1rem;
			border-bottom: 2px solid #fff;
			font-weight: bold;
			font-family: 'Lato', Verdana, sans-serif;

			grid-template-columns: 3fr 1fr 1fr;
			// background-color: #fff;
			border: 1px solid #dfe2e6;
			// padding: 20px;
			margin: 0;
			@media (max-width: 840px) {
				grid-template-columns: unset;
				grid-template-rows: 1fr 1fr 1fr;
			}

			.observacoes {
				font-size: 1rem;
				color: #212529;
				line-height: 32px;
				background-color: #f5f5f575;
				padding: 0px 8px;
				height: 32px;
				white-space: nowrap;

				&.atingida {
					color: #157861;
				}

				&.nao-atingida {
					color: rgb(196, 45, 45);
				}
			}
		}

		&.erros {
			box-shadow: 0 4px 8px 0 rgb(196, 45, 45);
		}

		&:first-child {
			margin-top: 0;
		}
	}

	.form-group label span {
		font-size: 15px;
		line-height: 22px;
		margin-left: 3px;
		// text-transform: capitalize;
	}
	.form-group .pergunta-input label {
		font-size: 15px !important;
		line-height: 22px;
		margin-left: 3px;
	}

	.form-check.form-check-inline {
		margin-bottom: 10px;
	}

	.cs-observacoes {
		font-style: italic;
		color: #979595;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	fieldset {
		// margin-top: 1rem;
		// margin-bottom: 1rem;

		// legend {
		// 	text-transform: uppercase;
		// 	font-weight: 600;
		// 	font-size: 18px;
		// 	color: #687c80;
		// 	margin-bottom: 12px;
		// }

		legend {
			font-style: italic;
			color: #505f62;
			margin: 30px 0 15px -50px;
			font-size: 16px;
			font-weight: 400;
			background: #e4e5e5;
			padding: 2px 10px 2px 30px;
			display: inline-block;
			width: auto;
		}
	}

	.form-list > .form-list-item {
		// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		border: 3px solid rgba(0, 0, 0, 0.2);
		// background-color: rgba(233, 235, 236, 0.801);
		transition: 0.3s;
		margin: 1rem 0 0.5rem 0;
		padding: 0 0 1rem 0;
	}

	.card-body {
		padding: 40px;
	}

	.cs-observacoes {
		margin-left: 13px;
	}
	.row.linha-opcoes-resposta {
		margin-left: -1px;
	}
	.numero input {
		height: 52px;
	}
	.numero {
		margin-bottom: 1rem;
		margin-right: 15px;
		margin-left: -5px;

		span.required {
			display: contents;
		}
	}
	.numero label.control-label {
		padding-right: 0;
	}
}

.card .control-label {
	margin-top: 0;
}

.modal .modal-title {
	font-size: 31px;
	margin-top: 18px;
	margin-bottom: 12px;
	font-weight: 600;
	display: block;
	line-height: 1;
}

.numero-pranchas .form-check-inline {
	white-space: nowrap;
}

.perguntas.metragem.descricao-pranchas {
	margin-top: 10px;
}

.endereco-obra {
	margin-bottom: 10px;
	background-color: #f7f7f7;
	padding: 15px 20px;
}

.endereco-obra .cdl-field label.control-label {
	font-size: 14px;
	font-weight: normal;
	color: #7d7f80;
	line-height: 1;
	display: block;
	margin: 0;
	letter-spacing: normal;
	text-transform: none;
	width: 100%;
}

.endereco-obra .cdl-field .col-md-8 {
	padding-left: 0;
}

.endereco-obra .cdl-field .col-md-4 {
	padding-right: 0;
}

.condicionante-resposta {
	border-left: 2px dotted #687c8040;
	margin: 20px 0 20px 30px;
	padding: 10px 0px 10px 12px;
}

.condicionante-resposta .d-flex {
	display: block !important;
}

.condicionante-texto {
	font-size: 14px;
}

.condicionante-numero-sei {
	label {
		font-size: 14px !important;
		color: #7d7f80 !important;
	}
}

.data-projeto-licenca {
	input {
		position: relative;
		right: -36px;
	}
	.btn-calendar {
		position: relative;
		bottom: 0;
	}
}

.card {
	.pergunta.metragem {
		select.form-control {
			padding: 8px 16px;
		}
	}
}

.card-nome-endereco .row {
	display: flex;
	align-items: flex-end;
}

.modal-dialog.modal-90w {
	// max-width: 1000px;
	left: -5px;
}

.modal-content {
	background-color: #edeff1 !important;
	border-radius: 0;
}

.modal-header .close {
	background-color: #edeff1 !important;
}

.modal-header,
.modal-body {
	padding: 30px !important;
}

h2.titulo-certificacao-almejada {
	// text-transform: uppercase;
	font-weight: 600;
	font-size: 18px;
	color: #687c80;
	margin-bottom: 12px;
	line-height: 24px;
	max-width: 600px;
	@media (max-width: 840px) {
		& + img {
			width: 137%;
		}
	}
}

.cs-dimensao {
	color: #000000d1;
	text-transform: uppercase;
	font-size: 13px;
}

.cs-dimensao::before {
	content: 'Dimensão ';
}

span.cs-numero {
	color: #687c81;
	font-weight: 800;
	font-size: 18px;
}

span.cs-numero:after {
	content: '.';
	margin-right: 10px;
}

.cs-super-label,
.cs-label {
	display: inline;
}

.cs-super-label {
	font-weight: bold;
	color: #687c80;
	text-transform: uppercase;
	font-size: 0.95em;
	margin-right: 8px;
}

.cs-warning {
	display: flex;
	color: #fa970f;
	font-weight: 700;
	font-style: italic;
	font-size: 1.2rem;
	.fa {
		font-size: 3rem;
		margin-right: 10px;
	}
	span {
		flex: 1;
	}
}

.cs-pergunta-warning {
	color: #fa970f;
	font-style: italic;
	font-weight: 400;
	margin-top: -10px;
	margin-bottom: 20px;
	padding-left: 20px;
	margin-right: 15px;
	.fa {
		margin-right: 5px;
	}
}

.nome-endereco-certificado {
	border-bottom: 2px solid #dfe2e6;
	padding-bottom: 3rem;
	margin-bottom: 3rem;

	.pergunta {
		padding: 0;

		&:hover {
			background-color: transparent;
		}
	}
}
