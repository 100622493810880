.doc-licencas-expressas {
	fieldset {
		background-color: #fff;
		border: 1px solid #dfe2e6;
		padding: 20px;
		margin-top: 1rem;

		div.titulo-licencas-expressas {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 18px;
			color: #687c80;
			margin-bottom: 2rem;
			margin-top: 1rem;
		}
	}

	p.observacoes {
		border-left: 2px solid #687c80;
		padding-left: 1rem;
		font-style: italic;
		max-width: 820px;
		margin-left: 10px;
	}

	label {
		white-space: nowrap;
	}
	.text-wrap {
		label {
			white-space: normal;
		}
	}
}

.pergunta {
	label {
		line-height: 1.3rem;
		padding-right: 28px;
	}

	.form-check-label {
		padding-right: 0px;
	}

	.form-check-inline {
		margin-right: 14px;
	}

	.d-flex {
		align-items: flex-start;
	}
}

.pergunta {
	border-bottom: 2px solid #e4e5e5;
	padding: 14px 10px;

	&:hover {
		background: #f6f6f6;
	}

	&:last-child {
		border: none;
	}

	.pergunta {
		padding-left: 20px !important;
		border-bottom: 1px solid #e4e5e5;
		padding-right: 0;

		&:last-child {
			border: none;
		}

		.observacao-le,
		.observacao-vistoria {
			margin-left: 2px;
			padding-left: 14px;
		}
	}
}

.pergunta.metragem {
	padding-top: 4px;
	padding-bottom: 4px;

	// para deixar a linha menos alta - avaliar
	input {
		// width: 130px;
		margin: 0;
	}

	.error-message,
	.warning-message {
		margin: 8px 0;
		font-size: 14px;
		font-style: italic;
	}

	.error-message {
		color: #bb2929;
		line-height: 18px;
	}

	ul.error-block.error-message {
		margin: 0;
	}

	.d-flex {
		align-items: center;
		// para deixar a linha menos alyta - avaliar
	}
}

.pergunta,
.pergunta.metragem {
	.error-message,
	.warning-message {
		margin: 8px 0;
		font-size: 14px;
		font-style: italic;
	}

	.error-message {
		color: #bb2929;
	}
}

/*.pergunta.metragem {
	.title-with-button {
		width: 140px;
	}
}*/

.metragem.anexo72 {
	.d-flex {
		align-items: center;
	}
}

.erros {
	box-shadow: 0 4px 8px 0 rgba(240, 62, 62, 0.4);
}

.btn-trash-simples-natureza {
	top: 58px;
}

.pergunta.label-longa label {
	white-space: initial;
}
