.pda-ativ {
	display: grid;
	grid-template-columns: 6fr 1fr;
	border: 1px solid rgba(105, 124, 128, 0.5);
	background-color: rgba(555, 555, 555, 0.7);
	margin: 20px 15px;
	padding: 10px;
	border-radius: 0.2rem;
	&.pda-ativ-removida,
	&.pda-ativ-adicionada {
		grid-template-columns: 3fr 1fr;
	}
	&.pda-ativ-removida {
		background-color: rgba(255, 217, 136, 0.274);
	}
	&.pda-ativ-adicionada {
		background-color: rgba(136, 178, 255, 0.274);
	}
}
