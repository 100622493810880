.doc-licenciamento-expresso,
.doc-vistoria {
	.required,
	:required {
		color: red;
	}

	button:disabled {
		background-color: red;
	}

	.le-card {
		// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		// transition: 0.3s;
		// width: 100%;
		// padding: 1px 16px;
		// margin-top: 1rem;
		// background-color: rgb(250, 250, 250);
		background-color: #fff;
		border: 1px solid rgb(223, 226, 230);
		// padding: 1px 16px;
		padding: 20px;
		margin-top: 1rem;

		&.erros {
			box-shadow: 0 4px 8px 0 rgba(240, 62, 62, 0.4);
		}

		&:first-child {
			margin-top: 0;
		}

		.selo {
			position: relative;

			img {
				position: absolute;
				box-shadow: 7px 4px 7px rgba(0, 0, 0, 0.2);
				top: -31px;
				right: 10px;
			}
		}
	}

	fieldset {
		// margin-top: 1rem;
		// margin-bottom: 1rem;

		legend {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 18px;
			color: #687c80;
			margin-bottom: 12px;
		}

		.legend_secundaria {
			font-style: italic;
			color: #505f62;
			margin: 30px 0 0px -26px;
			font-size: 16px;
			font-weight: 400;
			background: #e4e5e5;
			padding: 2px 10px 2px 30px;
			display: inline-block;
		}
	}

	.le-form-list > .le-form-list-item {
		// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		border: 3px solid rgba(0, 0, 0, 0.2);
		// background-color: rgba(233, 235, 236, 0.801);
		transition: 0.3s;
		margin: 1rem 0 0.5rem 0;
		padding: 0 0 1rem 0;
	}

	table.table-habitacao {
		border-collapse: separate !important;
		border-spacing: 0 !important;

		thead > tr > th {
			text-align: center;
			padding: 0 0.2rem;
			white-space: normal;
			background-color: #ebebeb;
		}

		tbody > tr > td {
			text-align: center;
			padding: 0rem 0.2rem;
		}

		tfoot > tr > td {
			text-align: center;
			padding: 0.2rem;
		}

		.total-geral {
			background-color: #09afa7;
			color: #fff;
		}

		.subtotal-pavimento {
			background-color: #28afa7;
			color: #fff;
		}

		.subtotal-aumento {
			background-color: #28afa75c;
		}

		.subtotais {
			background-color: #d4d4d5;
		}

		.subtotal {
			background-color: darkgray;
		}

		.realce {
			background-color: #545248;
			mix-blend-mode: darken;
			color: #fff;
		}

		td.cell-input {
			padding: 0px 2px;
			padding-bottom: 3px;
			min-width: 5rem;
			font-size: small;
			overflow: auto;

			& input,
			textarea {
				border: 0px;
				padding-left: 2px;
				padding-right: 0px;
				margin-top: -15px;
				overflow-y: hidden;
			}

			& input:focus,
			textarea:focus {
				box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			}
		}

		td.cell-button {
			padding: 2px;
		}
	}

	.painel-agrupador {
		padding: 10px;

		&:last-child {
			border-bottom: 2px solid #e4e5e5;
		}

		.label-anexos {
			font-weight: bold;
			font-size: 10pt;
		}

		.super-grupo {
			border: 2px solid #687c80;

			.header-super-grupo {
				padding: 17px 0 9px;
				cursor: pointer;
				background-color: #687c80;

				h2 {
					margin: 0px;
					margin-left: 10px;
					margin-top: -10px;
					// font-size: 18px;
					color: #fff;
					text-transform: uppercase;
					font-size: 16px;
					font-weight: 600;

					span {
						margin-left: 5px;
					}
				}
			}

			.error-super-grupo {
				color: #cc3e3e;
				font-size: 22pt;
				line-height: 13pt;
			}

			.info-super-grupo {
				color: adjust-hue(saturate(darken(blue, 30%), 20%), -15%);
				font-size: 22pt;
				line-height: 13pt;
			}

			.warning-super-grupo {
				color: adjust-hue(saturate(darken(yellow, 30%), 20%), -15%);
				font-size: 22pt;
				line-height: 13pt;
			}
		}

		.grupo {
			// border: 1px solid #ededed;
			padding: 16px;
			// margin-bottom: 15px;
			// background-color: #ededed;

			// label {
			// color: #212529;
			// }

			h3 {
				font-weight: 400;
				padding: 12px 0 0 10px;
			}

			input,
			select {
				background-color: #fff;

				&:disabled {
					// background-color: transparent;
					cursor: not-allowed;
				}

				// &:read-only {
				// background-color: transparent;
				// }
			}

			textarea {
				border: 1px solid rgba(104, 123, 128, 0.4);
				border-radius: 0;
				background-color: #fff;

				&:disabled {
					border: 0;
					background-color: transparent;
				}

				&:read-only {
					border: 0;
					background-color: transparent;
				}
			}

			span {
				white-space: pre-wrap;
			}
		}
	}

	.conjuntos-atividades {
		border: 1px solid rgba(105, 124, 128, 0.5);
	}
}

.celula-descricao-principal,
.celula-adicionar,
.celula-nome-pavimento,
.celula-subtotais,
.celula-total {
	position: sticky;
	left: 0;
}

.celula-nome-pavimento {
	background-color: #fff;
}

.celula-excluir {
	position: sticky;
	left: 84px;
	background-color: #fff;
}

.celula-excluir-simples {
	position: sticky;
	left: 0;
	background-color: #fff;
}

.celula-excluir-2eco {
	position: sticky;
	left: 90px;
	background-color: #fff;
}

.celula-descricao {
	position: sticky;
	left: 118px;
	background-color: #fff;
}

.celula-descricao-simples {
	position: sticky;
	left: 35px;
	background-color: #fff;
}

.celula-descricao-2eco {
	position: sticky;
	left: 125px;
	background-color: #fff;
}

.celula-nome-2eco {
	text-align: left;
	margin: 5px 0 5px 2px;
	font-weight: bold;
}

.id-le,
.id-vist {
	margin-bottom: 20px;
	align-items: end;

	label {
		color: #687c80 !important;
		line-height: 1 !important;
		display: block !important;
		font-size: 14px !important;
	}

	.endereco {
		ul {
			list-style: none;
			padding-left: 0;

			& li:before {
				content: '\f041';
				display: inline-block;
				font: normal normal normal 14px/1 FontAwesome;
				color: #687c80;
				margin-right: 8px;
			}
		}
	}

	.nao-informado {
		&:before {
			content: '\f128';
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			color: #687c80;
			margin-right: 8px;
		}
	}

	.exp-un,
	.area-priv {
		span:not(.nao-informado) {
			font-size: 28px;
			font-weight: 700;
		}
	}

	.nome-lot {
		.control-label {
			margin-top: 0;
		}

		input {
			width: 250px;
		}
	}
}

.id-le-dinamico,
.id-vist-dinamico {
	display: flex;
	justify-content: space-between;
}

.destaque-pergunta {
	font-weight: bolder;
	background: #fff7c8;
	padding: 2px 6px;
}

.card-deck {
	.card {
		border-radius: 0;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

.card-body.form-group {
	margin-top: 0;
	margin-bottom: 0;

	label {
		font-size: 14px;
		font-weight: normal;
		line-height: 1.3rem;
	}
}

.observacao-le {
	font-style: italic;
	font-size: 14px;
	font-weight: 400 !important;
	margin: 20px 0 20px 2px;
	// border-left: 10px solid #687c8040;
	border-left: 2px dotted #687c8040;
	padding: 0 14px;
	color: #687c80;
	line-height: 1.4rem;
}

.observacao-le-error {
	font-style: italic;
	font-size: 14px;
	font-weight: 400 !important;
	margin: 20px 0 20px 2px;
	// border-left: 10px solid #687c8040;
	border-left: 2px dotted #bb2929;
	padding: 0 14px;
	line-height: 1.4rem;
	color: #bb2929;
}

.pergunta {
	label {
		line-height: 1.3rem;
		padding-right: 28px;
	}

	.form-check-label {
		padding-right: 0px;
	}

	.form-check-inline {
		margin-right: 14px;
	}

	.d-flex {
		align-items: flex-start;
	}
}

// .meio-ambiente-le,
// .restricoes-projeto-le,
// .info-terreno-le,
// .projeto-le

.pergunta {
	border-bottom: 2px solid #e4e5e5;
	padding: 14px 10px;

	&:hover {
		background: #f6f6f6;
	}

	&:last-child {
		border: none;
	}

	.pergunta {
		padding-left: 20px !important;
		border-bottom: 1px solid #e4e5e5;
		padding-right: 0;

		&:last-child {
			border: none;
		}

		.observacao-le,
		.observacao-vistoria {
			margin-left: 2px;
			padding-left: 14px;
		}
	}
}

.pergunta.metragem {
	padding-top: 4px;
	padding-bottom: 4px;

	// para deixar a linha menos alta - avaliar
	input {
		// width: 130px;
		margin: 0;
	}

	.error-message,
	.warning-message {
		margin: 8px 0;
		font-size: 14px;
		font-style: italic;
	}

	.error-message {
		color: #bb2929;
	}

	ul.error-block.error-message {
		margin: 0;
	}

	.d-flex {
		align-items: center;
		// para deixar a linha menos alyta - avaliar
	}
}

.pergunta,
.pergunta.metragem {
	.error-message,
	.warning-message {
		margin: 8px 0;
		font-size: 14px;
		font-style: italic;
	}

	.error-message {
		color: #bb2929;
	}
}

/*.pergunta.metragem {
	.title-with-button {
		width: 140px;
	}
}*/

.metragem.anexo72 {
	.d-flex {
		align-items: center;
	}
}

button {
	border-radius: 0 !important;
}

.tituloLE {
	font-size: 32px;
	margin-top: 8px;
	margin-bottom: 2px;
	font-weight: 400;
	display: block;
	line-height: 1;
}

.subTituloLE {
	text-transform: uppercase;
	font-size: 14px;
	margin-left: 2px;
	font-weight: 700;
}

.form-check-label {
	display: inline-flex;
}

.campo-automatico {
	position: absolute;
	right: 10px;
	top: 12px;
	opacity: 0.5;
}

.campo-automatico-selecao {
	position: relative;
	// top: 4px;
	opacity: 0.5;
	left: -25px;
	z-index: 1000;
}

.pergunta.selecao2 {
	.campo-automatico-selecao {
		left: 95% !important;
		top: -32px !important;
	}
}

.control-label,
label {
	margin-bottom: 6px;
}

.m2-input {
	font-size: 12px;
	position: absolute;
	right: 10px;
	top: 12px;
	color: grey;
}

.selecao {
	padding: 20px 10px;
	// border-bottom: 2px solid #e4e5e5;
}

.subunidadeLE {
	.d-flex {
		margin-right: -14px;
	}
	.pergunta.metragem input {
		width: 150px;
	}
	.selecao2 .d-flex {
		margin-right: 0px;
		align-items: center;

		.input-group-lic {
			width: 190px;
		}
	}

	.input-floating-list {
		z-index: 9999;
	}
}

.btn-tabela-areas {
	line-height: 12px !important;
	border-width: 0.18em !important;
	padding: 5px !important;
	text-transform: uppercase !important;
	font-size: 0.8rem !important;
	font-weight: bold !important;
	letter-spacing: 0.05em !important;
}

table#table-areas {
	font-size: 14px;
	color: #4d4c4c;
}

.btn-trash-tabela-areas {
	color: #dc3545 !important;
	font-size: 16px !important;
	padding: 2px;

	&:disabled {
		background-color: transparent !important;
		opacity: 0.6;
	}
}

.doc-licenciamento-expresso .plano-diretor .header-super-grupo {
	display: none;
}

.doc-licenciamento-expresso .plano-diretor .obs-plano-diretor {
	background-color: #fff !important;
	padding: 0 !important;
}

.doc-licenciamento-expresso .plano-diretor .super-grupo {
	border: 0 !important;
}

.legenda-titulo-secundario {
	text-transform: initial !important;
	margin-top: 30px;
	margin-bottom: 12px !important;
	font-size: 18px !important;
}

.legenda-titulo-secundario.dados-iniciais {
	margin-top: 0px;
}

.titulo-subunidade-le {
	font-style: italic;
	color: #505f62;
	margin: 30px 0 0px -26px;
	font-size: 18px;
	font-weight: 600;
	background: #e4e5e5;
	padding: 6px 10px 6px 30px;
	display: inline-block;
	margin-top: 10px !important;
	margin-bottom: 30px !important;
}

.projeto-le .grupo .form-group label {
	font-size: 14px;
	font-weight: normal;
	color: #3a3a3b;
	line-height: 1;
	display: block;
	margin: 0;
	letter-spacing: normal;
	text-transform: none;
	width: 100%;
}

.projeto-le .form-group.col-md-6 {
	padding-left: 0;
}

.projeto-le .grupo {
	margin-bottom: 50px;
}

.projeto-le .dados-atividades-subunidades {
	margin: 20px 0 !important;
	border: 1px dashed #9b9d9d !important;
	border-radius: 0 !important;
}

/*.conjunto-dados-projeto-subunidade {
	border-bottom: 2px dotted #505f62;
	padding-bottom: 40px;
	margin-bottom: 40px;
}*/

.ap1800-planilha .ap1800-planilha-titulos {
	font-weight: 400;
	font-size: 14px;
}
