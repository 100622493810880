.plano-diretor {

	.label-anexos {
		font-weight: bold;
		font-size: 10pt;
	}

	.super-grupo {
		border: 2px solid rgba(105, 124, 128, 0.5);
		margin-bottom: 15px;

		.header-super-grupo {
			padding: 17px 0 9px;
			cursor: pointer;
			background-color: rgba(104, 124, 128, 0.25);

			h2 {
				margin: 0px;
				margin-left: 10px;
				margin-top: -10px;
				font-size: 18px;
				color: #697c80;

				span {
					margin-left: 5px;
				}
			}
		}

		.error-super-grupo {
			color: #cc3e3e;
			font-size: 22pt;
			line-height: 13pt;
		}
		.info-super-grupo {
			color: adjust-hue(saturate(darken(blue, 30%), 20%), -15%);
			font-size: 22pt;
			line-height: 13pt;
		}
		.warning-super-grupo {
			color: adjust-hue(saturate(darken(yellow, 30%), 20%), -15%);
			font-size: 22pt;
			line-height: 13pt;
		}
	}

	.obs-plano-diretor {
		background-color: #ededed;
		padding: 0 0 10px 13px;
	}

	.grupoBpmTasks {
		border: 1px solid #ededed;
		padding: 15px 12px 8px 12px;
		margin-bottom: 15px;
		background-color: #ededed;

		label {
			color: #212529;
		}

		h3 {
			background-color: #fff;
			padding: 8px 12px;
			margin: -14px -11px 10px -11px;
			font-size: 1em;
			cursor: pointer;
		}

		input,
		select {
			background-color: #fff;

			&:disabled {
				background-color: transparent;
				cursor: not-allowed;
			}
		}

		textarea {
			border: 1px solid rgba(104, 123, 128, 0.4);
			border-radius: 0;
			background-color: #fff;

			&:disabled {
				border: 0;
				background-color: transparent;
			}
		}

		span {
			white-space: pre-wrap;
		}
	}
}
