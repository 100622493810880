$primary-color: #09afa7;

.doc-ap,
.doc-vistoria {
	.required,
	:required {
		color: red;
	}

	.ap-card {
		// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		// transition: 0.3s;
		// width: 100%;
		// padding: 1px 16px;
		// margin-top: 1rem;
		// background-color: rgb(250, 250, 250);
		background-color: #fff;
		border: 1px solid rgb(223, 226, 230);
		// padding: 1px 16px;
		padding: 20px;
		margin-top: 1rem;

		&.erros {
			box-shadow: 0 4px 8px 0 rgba(240, 62, 62, 0.4);
		}

		&:first-child {
			margin-top: 0;
		}

		.selo {
			position: relative;

			img {
				position: absolute;
				box-shadow: 7px 4px 7px rgba(0, 0, 0, 0.2);
				top: -31px;
				right: 10px;
			}
		}
	}

	fieldset {
		// margin-top: 1rem;
		// margin-bottom: 1rem;

		legend {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 18px;
			color: #687c80;
			margin-bottom: 12px;
		}

		.legend_secundaria {
			font-style: italic;
			color: #505f62;
			margin: 30px 0 0px -26px;
			font-size: 16px;
			font-weight: 400;
			background: #e4e5e5;
			padding: 2px 10px 2px 30px;
			display: inline-block;
		}
	}

	.le-form-list > .le-form-list-item {
		// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		border: 3px solid rgba(0, 0, 0, 0.2);
		// background-color: rgba(233, 235, 236, 0.801);
		transition: 0.3s;
		margin: 1rem 0 0.5rem 0;
		padding: 0 0 1rem 0;
	}

	table.table-ap {
		border-collapse: separate !important;
		border-spacing: 0 !important;
		margin-bottom: 0.3rem;

		tr > th {
			text-align: center;
			padding: 0 0.2rem;
			white-space: normal;
			background-color: #ebebeb;
			border: 1px solid #cacbcc;
			position: sticky;
			inset-block-start: 0;
			z-index: 2;
			&.scope-row {
				z-index: 3;
			}
			&.scope-index-upper {
				z-index: 6;
			}
			&.scope-line2 {
				top: 23px;
			}
			&.scope-line3 {
				top: 46px;
			}
			&.scope-area-even {
				background-color: #e2ebeb;
			}
			&.box-shadow-down {
				box-shadow: 3px 3px 5px #00000040;
			}
			&.scope-border-left {
				border-left: 1px solid #979797;
			}
			&.scope-border-right {
				border-right: 1px solid #979797;
			}
			&.scope-border-top {
				border-top: 1px solid #979797;
			}
			&.scope-border-bottom {
				border-bottom: 1px solid #979797;
			}
		}
		&.table-ap-history-view tr > th {
			background-color: #f3f2e8 !important;
		}

		tr > td {
			text-align: center;
			padding: 0.2rem;

			border: 1px solid #cacbcc;
			&.box-shadow-right {
				box-shadow: 3px 3px 5px #00000040;
			}
			&.scope-bloco-even {
				background-color: #e9efef;
			}
			&.scope-area-even {
				background-color: #d7e6e6;
			}
			&.scope-opts-bloco {
				min-width: 10rem;
			}
			&.scope-index-upper {
				z-index: 5;
			}
			&.scope-column2 {
				left: 10rem;
				min-width: 10rem;
				z-index: 4;
			}
			&.scope-column3 {
				left: 20rem;
				min-width: 10rem;
				z-index: 3;
			}
			&.scope-border-left {
				border-left: 1px solid #979797;
			}
			&.scope-border-right {
				border-right: 1px solid #979797;
			}
			&.scope-border-top {
				border-top: 1px solid #979797;
			}
			&.scope-border-bottom {
				border-bottom: 1px solid #979797;
			}
		}

		.total-geral {
			background-color: #09afa7;
			color: #fff;
			padding: 1rem;
			text-align: center;
			vertical-align: middle;
		}
		&.table-ap-history-view .total-geral {
			background-color: #af9e09 !important;
		}

		.subtotais {
			white-space: nowrap;
			padding: 1rem;
			background-color: #ebebeb;
			min-width: 5rem;
			color: #cdcdcd;
			&.subtotais-value {
				color: #25a098 !important;
				font-weight: bold !important;
			}
		}
		&.table-ap-history-view .subtotais {
			background-color: #f3f2e8 !important;
			&.subtotais-value {
				color: #25a098 !important;
				font-weight: bold !important;
			}
		}

		.realce {
			background-color: #545248;
			mix-blend-mode: darken;
			color: #fff;
		}

		td.cell-input {
			padding: 0px 2px;
			padding-bottom: 3px;
			min-width: 5rem;
			font-size: small;
			overflow: auto;
			align-content: baseline;

			& input,
			textarea {
				border: 0px;
				padding-left: 2px;
				padding-right: 0px;
				// margin-top: -15px;
				overflow-y: hidden;
			}

			& input:focus,
			textarea:focus {
				box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			}

			.cell-input-wrapper {
				display: flex;
				gap: 3px;
				align-items: baseline;
			}

			&.cell-disabled {
				align-content: unset;

				.cell-input-wrapper {
					display: flex;
					gap: 3px;
					align-items: unset;
				}
			}
		}
		&.table-ap-history-view td.cell-input input {
			background-color: #d9d8cb !important;
		}
	}
}

.celula-subtotais {
	// border-left: 2px solid #979797 !important;
	// border-right: 2px solid #979797 !important;
	// border-bottom: 2px solid #979797 !important;
}

th.subtotais.box-shadow-down.scope-index-upper {
	border-top: 1px solid #979797 !important;
	border-right: 1px solid #979797 !important;
	border-bottom: 1px solid #979797 !important;
	color: #4d4c4c !important;
}

.grupo-indice-construtivo .input-group-lic {
	max-width: 200px;
}

.grupo-indice-construtivo {
	display: flex;
	align-items: flex-start;
	align-self: flex-end;
	justify-content: flex-start;
	flex-wrap: wrap;
	background-color: #eee !important;
	padding: 10px;
	margin: 10px;
}

span.planilha-indices-construtivos-titulos {
	font-weight: bold;
	margin-left: 10px;
}

.planilha-indices-construtivos .btn-add-linha,
.planilha-indices-construtivos .btn-add-obs {
	border: 2px solid #09afa7;
	margin-bottom: 1px;
	line-height: 24px;
	background-color: #09afa7;
	color: #fff;
	padding: 5px;
	margin-right: 10px;

	&:hover {
		color: #fff;
		cursor: pointer;
		text-decoration: underline;
	}

	.fa {
		margin-right: 5px;
	}

	&:focus {
		outline: none;
		box-shadow: none !important;
	}
}

.btns-add-linha-obs {
	margin-top: 6px;
	margin-left: 10px;
}

.linha-obs {
	flex-basis: 100%;
	margin-right: -30px;
}

td.subtotais {
	// border-left: 2px solid #979797 !important;
	// border-bottom: 2px solid #979797 !important;
	color: #4d4c4c;
	&.subtotais-value {
		color: #25a098 !important;
		font-weight: bold !important;
	}

	&:last-child {
		border-left: 0 !important;
	}
}

.doc-ap table.table-ap .subtotais,
.doc-vistoria table.table-ap .subtotais {
	padding: 0;
}

.celula-descricao-principal,
.celula-subtotais,
.celula-total {
	position: sticky;
	left: 0;
}

.celula-coluna-economia,
.celula-coluna-pavimento,
.celula-coluna-nat-esp {
	position: sticky;
	left: 0;
	background-color: #fff;
}

.id-ap {
	margin-bottom: 20px;
	align-items: end;

	label {
		color: #687c80 !important;
		line-height: 1 !important;
		display: block !important;
		font-size: 14px !important;
	}

	.endereco {
		ul {
			list-style: none;
			padding-left: 0;

			& li:before {
				content: '\f041';
				display: inline-block;
				font: normal normal normal 14px/1 FontAwesome;
				color: #687c80;
				margin-right: 8px;
			}
		}
	}

	.nao-informado {
		&:before {
			content: '\f128';
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			color: #687c80;
			margin-right: 8px;
		}
	}

	.exp-un,
	.area-priv {
		span:not(.nao-informado) {
			font-size: 28px;
			font-weight: 700;
		}
	}

	.nome-lot {
		.control-label {
			margin-top: 0;
		}

		input {
			width: 250px;
		}
	}
}

.id-ap-dinamico {
	display: flex;
	justify-content: space-between;
}

.observacao-ap {
	font-style: italic;
	font-size: 14px;
	font-weight: 400 !important;
	margin: 20px 0 20px 2px;
	// border-left: 10px solid #687c8040;
	border-left: 2px dotted #687c8040;
	padding: 0 14px;
	color: #687c80;
	line-height: 1.4rem;
}

.pergunta {
	label {
		line-height: 1.3rem;
		padding-right: 28px;
	}

	.flex-start {
		align-items: flex-start;

		.input-group-lic {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			align-items: center;
			gap: 20px;
		}
	}

	border-bottom: 2px solid #e4e5e5;
	padding: 14px 10px;

	&:hover {
		background: #f6f6f6;
	}

	&:last-child {
		border: none;
	}

	.pergunta {
		padding-left: 20px !important;
		border-bottom: 1px solid #e4e5e5;
		padding-right: 0;

		&:last-child {
			border: none;
		}

		.observacao-ap {
			margin-left: 2px;
			padding-left: 14px;
		}
	}

	.error-message,
	.warning-message {
		margin: 8px 0;
		font-size: 14px;
		font-style: italic;
	}

	.error-message {
		color: #bb2929;
	}
}

.form-check-label {
	display: inline-flex;
}

.pergunta.selecao2 {
	.campo-automatico-selecao {
		left: 97% !important;
		top: -32px !important;
	}
}

.control-label,
label {
	margin-bottom: 6px;
}

.selecao {
	padding: 20px 10px;
	// border-bottom: 2px solid #e4e5e5;
}

.d-flex {
	justify-content: space-between;
}

.subunidadeLE {
	.d-flex {
		margin-right: -14px;
	}

	.pergunta.metragem,
	.pergunta.selecao2 {
		padding: 4px 0px;
	}

	.pergunta.metragem input {
		width: 150px;
	}

	.pergunta.selecao2.small input {
		max-width: 150px;
		min-width: 150px;
	}

	.selecao2 {
		.d-flex {
			margin-right: 0px;
			align-items: center;

			.input-group-lic {
				width: 190px;
			}
		}
	}
	.selecao2.small {
		.d-flex {
			.input-group-lic {
				width: 150px;
			}
		}
	}

	.input-floating-list {
		z-index: 9999;
	}
}

.btn-tabela-areas {
	line-height: 12px !important;
	border-width: 0.18em !important;
	padding: 5px !important;
	text-transform: uppercase !important;
	font-size: 0.8rem !important;
	font-weight: bold !important;
	letter-spacing: 0.05em !important;
	margin-top: 10px !important;
}

table#table-areas {
	font-size: 14px;
	color: #4d4c4c;

	thead {
		position: -webkit-sticky; /* for Safari */
		position: sticky;
		top: 0;
		left: 0;
		z-index: 1;
	}
}

.btn-trash-tabela-areas {
	color: #dc3545 !important;
	font-size: 16px !important;
	padding: 2px;

	&:disabled {
		background-color: transparent !important;
		opacity: 0.6;
	}
}

.btn-edit-tabela-areas {
	// color: #dc3545 !important;
	font-size: 16px !important;
	padding: 2px;

	&:disabled {
		background-color: transparent !important;
		opacity: 0.6;
	}
}

.modal-dialog.modal-25w {
	max-width: 350px !important;
	left: -5px;
}

.doc-ap .plano-diretor .header-super-grupo {
	display: none;
}

.doc-ap .plano-diretor .obs-plano-diretor {
	background-color: #fff !important;
	padding: 0 !important;
}

.doc-ap .plano-diretor .super-grupo {
	border: 0 !important;
}

.legenda-titulo-secundario {
	text-transform: initial !important;
	margin-top: 30px;
	margin-bottom: 12px !important;
	font-size: 18px !important;
}

.legenda-titulo-secundario.dados-iniciais {
	margin-top: 0px;
}

.titulo-subunidade-ap {
	font-style: italic;
	color: #505f62;
	margin: 30px 0 0px -26px;
	font-size: 18px;
	font-weight: 600;
	background: #e4e5e5;
	padding: 6px 10px 6px 30px;
	display: inline-block;
	margin-top: 10px !important;
	margin-bottom: 30px !important;
}

.projeto-ap .grupo .form-group label {
	font-size: 14px;
	font-weight: normal;
	color: #3a3a3a;
	line-height: 1;
	display: block;
	margin: 0;
	letter-spacing: normal;
	text-transform: none;
	width: 100%;
}

/*.projeto-ap .form-group.col-md-6 {
	padding-left: 0;
}*/

/*.projeto-ap .grupo {
	margin-bottom: 50px;
	/*&.spaced-list {
		margin: 10px;
		box-shadow: 1px 2px 5px #00000026;
		padding: 20px;
	}*/

.projeto-ap .dados-atividades-subunidades {
	/*margin: 20px 0 !important;*/
	border: 1px dashed #9b9d9d !important;
	border-radius: 0 !important;
}

.conjunto-dados-projeto-subunidade {
	/*border-bottom: 2px dotted #505f62;
	padding-bottom: 40px;
	margin-bottom: 40px;*/
	&.subunidade-removida {
		background-color: #ffd98846;
		.titulo-subunidade-ap {
			background: #ebdead !important;
		}
	}
	&.subunidade-adicionada {
		background-color: #b3c6ed46;
		.titulo-subunidade-ap {
			background: #adc8eb !important;
		}
	}
}

.planilha-indices-construtivos {
	display: flex;
	// grid-template-columns: 3fr 1fr 100px;
	align-items: flex-start;
	// gap: 10px;
	background-color: #f6f6f6;

	.input-group-lic {
		min-width: 100px;
	}

	.planilha-indices-construtivos-titulos {
		/*text-align: right;*/
		font-weight: 700;
		margin-top: 6px;
		padding-left: 10px;
	}

	&.read-only {
		grid-template-columns: 3fr 1fr;

		.form-control {
			background-color: rgb(204, 204, 204);
			color: #3a4548;

			&:hover {
				background-color: rgb(204, 204, 204);
				color: #3a4548;
			}
		}
	}

	.planilha-indices-construtivos-titulos-totais {
		font-weight: 700;
		margin-top: 6px;
	}

	&.totais {
		grid-template-columns: 2fr 1fr 1fr;
	}

	// .btn-add-linha,
	// .btn-add-obs {
	// 	background-color: #fff;
	// 	border-radius: 2px;
	// 	padding: 1px;
	// 	color: #687c80;
	// 	border: 0;
	// 	margin-bottom: 2px;
	// 	margin-top: 5px;
	// 	font-size: 13px;
	// 	letter-spacing: 0.05em;
	// 	font-style: italic;
	// 	line-height: 15px;

	// 	i {
	// 		font-size: 12px;
	// 		margin-right: 4px;
	// 	}

	// 	&:hover {
	// 		color: #fa970f;
	// 		text-decoration: underline;
	// 	}
	// }
}

.planilha-indices-construtivos-qr {
	position: relative;
	.btn-modo-foco-historico {
		position: absolute;
		right: 2px;
		top: 15px;
	}
	&.modo-foco {
		.btn-modo-foco-historico {
			right: 34px;
			top: 50px;
		}
	}
	table#table-areas thead tr th:first-child {
		width: 25%;
	}
}

.pergunta.pergunta-indices-construtivos {
	border-bottom: 0;
}

td.subtotais.celula-subtotais.scope-index-upper {
	color: #4d4c4c;
	font-size: 14px;
	font-weight: bold;
}

.modal-dialog {
	max-width: 1000px !important;
}

.table-sticky-container {
	overflow: auto;
	max-height: 600px;
	border: 0 !important;
}

.modo-foco {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 95%;
	height: 95%;
	z-index: 1000;
	background-color: white;
	overflow: auto;
	border: 0 !important;
	padding: 2em;
	box-shadow: 0px 4px 12px #676767;

	.table-sticky-container {
		max-height: 90%;
		padding: 0 0.1rem 0.1rem 0;
	}
}

.btn-modo-foco {
	margin-bottom: 1em;
}

.tabela-areas-wrapper {
	&.modo-foco {
		.table-sticky-container {
			display: block;
			width: 100%;
		}
	}
	input[type='text'] {
		background-color: transparent;
	}

	td.cell-input {
		input {
			color: #cdcdcd;
			font-weight: bold;

			&::placeholder {
				color: #cdcdcd;
				font-weight: normal !important;
			}
		}
		&.cell-input-value {
			input {
				color: #25a098;
			}
		}
	}

	.totais-preenchidos {
		color: #25a098;
		font-weight: bold;
	}
}

.btn-modo-foco-historico {
	position: relative;
	float: right;
	padding-right: 32px !important;
	color: #664d00 !important;
	i {
		font-size: 20px;
		position: absolute;
		top: 9px;
		right: 11px;
		color: #ccaa00;
	}
	&:hover {
		background-color: #ccaa003f !important;
	}
	&.btn-modo-foco-inside {
		float: right;
		i {
			color: #463400;
		}
	}
}

.planilha-indices-construtivos {
	background-color: transparent;

	.planilha-indices-construtivos-titulos {
		font-weight: 400;
		font-size: 14px;

		strong {
			line-height: 39px;
			font-size: 13px;
		}
	}
	.form-control {
		height: 39px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: #edeff1 !important;
	}

	input[type='text'],
	input[type='date'],
	input[type='number'],
	input[type='tel'],
	input[type='month'],
	select,
	textarea {
		padding: 8px 10px;
		background-color: #fff !important;
		&[readonly] {
			background-color: #edeff1 !important;
		}
	}

	// .btn-add-linha {
	// 	border: 1px solid #c5c5c9;
	// 	margin-bottom: 1px;
	// 	font-size: 12px;
	// 	line-height: 13px;

	// 	&:hover {
	// 		color: #0aafa7;
	// 		cursor: pointer;
	// 	}
	// }
}

.pic-table-resumo {
	width: 100%;
	border: 1px solid #cdcdcd;
	margin-top: 25px;
	margin-bottom: 15px;

	th,
	td {
		border: 1px solid #cdcdcd;
		white-space: nowrap;
		vertical-align: bottom;

		&.center {
			text-align: center;
		}

		&.right {
			text-align: right;
		}
	}

	.pic-resumo-valor {
		text-align: right;
		padding: 5px;
		vertical-align: middle;
	}

	.pic-resumo-total {
		text-align: right;
		font-weight: bold;
		vertical-align: middle;
	}
}

.pic-resumo-achurado {
	background: linear-gradient(135deg, #d6d6d6 25%, transparent 25%) -50px 0,
		linear-gradient(225deg, #d6d6d6 25%, transparent 25%) -50px 0, linear-gradient(315deg, #d6d6d6 25%, transparent 25%),
		linear-gradient(45deg, #d6d6d6 25%, transparent 25%);
	background-size: 0.2em 0.2em;
	background-color: #f6f6f6;
}

.btn-add-comentarios {
	margin-bottom: 20px;
	position: relative;
	float: right;
	top: -6px;
	height: 27px !important;
	padding: 5px 10px !important;
}

.b-wrapper {
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 3;
}

.label-observacao {
	display: block;
	color: #7d7f80;
	text-align: justify;
	font-style: italic;
	font-size: 0.9rem;
	padding-left: 10px;
}
