.cal-range {
	.input-group-append {
		button {
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 5px;
			margin-left: 0px;
			margin-top: 6px;
		}
	}
}
